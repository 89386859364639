import React from 'react'
import moment from 'moment'
import { Button, Checkbox, Table, Modal, Upload, Icon, Tag, Col, DatePicker, Divider, Form, Input, Radio, Row, Select, message,Tabs,Tooltip, Popconfirm, } from 'antd'
import { compose, graphql, withApollo } from 'react-apollo';

import App from '../../App'
import EditSitMutate from '../../mutation/editSite'
import generateTransactionId from '../../mutation/generateTransactionId'
import sendWhatsApp from '../../mutation/sendWhatsApp'

import SitePages from '../../mutation/addPagesToSite'
import getSitePagesById from '../../queries/getStaticPages';
import PinchZoomPan from "react-responsive-pinch-zoom-pan";
import Resizer from "react-image-file-resizer";
import uuidv1 from 'uuid/v1';
import Highlighter from 'react-highlight-words';
import getSitePagesByAdmin from '../../queries/getSitePagesByAdmin'
import getSiteConfig from '../../queries/getSiteConfig'
import getSiteConfigServices from '../../queries/getSiteConfigServices'
import updateSiteConfig from '../../mutation/updateSiteConfig'
import createSiteConfig from '../../mutation/createSiteConfig'
import createSiteWallet from '../../mutation/createSiteWallet'
import AddExtraService from './addExtraServiceToSite'
import getSiteWalletById from '../../queries/getSiteWalletById'
import getSiteTransactionsByCreatedAt from '../../queries/getSiteTransactionsByCreatedAt'
import siteWalletRechargeByAdmin from '../../mutation/siteWalletRechargeByAdmin'
import SpAddToSite from './SpAddtoSite'
import Invoice from './Invoice'
import WhatsAppConfigModal from './whatsAppConfigModal';
import getSiteRewardSettingByDomain from '../../queries/getSiteRewardSettingByDomain';
import updateSiteRewardSetting from '../../mutation/updateSiteRewardSettingV2Alias'
import getInternalUsers from '../../queries/getInternalUsers'
import SiteActionEvent from '../../funComponent/SiteActionEvent'
import { currency,country } from '../../utils/countryCurrency';

let children = []
// const paymentModes = [
// {
//     internalName: "CASH",
//     // displayName: "CASH"
// },
// {
//     internalName: "CREDIT_CARD",
//     // displayName: "CREDIT CARD",
// },
// {
//     internalName: "DEBIT_CARD",
//     // displayName: "DEBIT CARD",
// },
// {
//     internalName: "NETBANKING",
//     // displayName: "NETBANKING"
// },
// {
//     internalName: "RAZORPAY",
//     // displayName: "RAZORPAY"
// },
// {
//     internalName: "PAYPAL",
//     // displayName: "PAYPAL"
// },
// {
//     internalName: "UPI",
//     // displayName: "UPI"
// },
// {
//     internalName: "PAYMENT_BTN",
//     // displayName: "PAYMENT BUTTON",
// },
// {
//     internalName: "AGGREGATOR_PAYMENT",
//     // displayName: "AGGREGATOR PAYMENT",
// },
// {
//     internalName: "MOBILE_MONEY",
//     // displayName: "MOBILE MONEY",
// },
// {
//     internalName: "VOUCHER",
//     // displayName: "VOUCHER"
// },
// {
//     internalName: "SWISH",
//     // displayName: "SWISH"
// },
// {
//     internalName: "WALLET",
//     // displayName: "WALLET"
// },
// {
//     internalName: "BANK_TRANSFER",
//     // displayName: "BANK TRANSER"
// },
// {
//     internalName: "CHEQUE",
//     // displayName: "CHEQUE"
// },
// ]

const { TabPane } = Tabs;

const bucket = process.env.REACT_APP_BUCKET
const S3_REGION = process.env.REACT_APP_S3_REGION
const S3_Url = process.env.REACT_APP_S3_URL;
let id = 0;
let dispVal = []

const { Option } = Select;
const { RangePicker } = DatePicker
const { TextArea } = Input;

const FormItem = Form.Item
const RadioGroup = Radio.Group;
const CheckboxGroup = Checkbox.Group;

const type = [
    { value: 'SIMPLE_B2C', label: 'e-Commerce' },
    { value: 'MARKETING', label: 'Marketing Site' },
    // { value: 'JEWEL_EXPO_BASIC', label: 'Jewel Expo Basic' },
    // { value: 'JEWEL_EXPO_PREMIUM', label: 'Jewel Expo Premium' }
]


const siteOptions = [
    { value: 'COMMERCE', label: 'e-Commerce' },
    { value: 'ENQUIRY', label: 'Enquiry' },
    // { value: 'LAUNDRY', label: 'Laundry' },
    // { value: 'EVENT', label: 'Event' },
    // { value: 'DOCTOR', label: 'Doctor' },
]


const payOptions = [
    // { label: 'No', value: 'NO' },
    { label: 'COD', value: 'COD' },
    { label: 'Razor Pay', value: 'RAZORPAY' },
    { label: 'CCAVENUE', value: 'CCAVENUE' },
    { label: 'PAYTM EDC', value: 'PAYTM_EDC' },
    { label: 'PAYTM QR', value: 'PAYTM_QR' },
    { label: 'Paypal', value: 'PAYPAL' },
    { label: 'Payment Button', value: 'PAYMENT_BTN' },
];

class EditSite extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            bg: this.props.location.state.siteData && this.props.location.state.siteData.backgroundColor ?
                this.props.location.state.siteData.backgroundColor : '#ffffff',
            showPicker: false,
            showMenu: false,
            serviceData: undefined,
            visibleM: false,
            paymentModal: false,
            allService: [],
            flag: false,
            userData: [],
            wallet: this.props.location.state.siteData && this.props.location.state.siteData.isWalletEnabled ?
                this.props.location.state.siteData.isWalletEnabled : false,
            isWebAppEnabled: this.props.location.state.siteData && this.props.location.state.siteData.isWebAppEnabled ?
                this.props.location.state.siteData.isWebAppEnabled : false,
            fontStyle: this.props.location.state.siteData && this.props.location.state.siteData.textFontType ?
                this.props.location.state.siteData.textFontType : '',
            textColor: this.props.location.state.siteData && this.props.location.state.siteData.textFontColor ?
                this.props.location.state.siteData.textFontColor : "#000000",
            menuColor: this.props.location.state.siteData && this.props.location.state.siteData.menuFontColor ?
                this.props.location.state.siteData.menuFontColor : '#ea2b1f',
            whatsappToSeller: this.props.location.state.siteData && this.props.location.state.siteData.whatsAppSeller != null ?
                this.props.location.state.siteData.whatsAppSeller : true,
            whatsappToBuyer: this.props.location.state.siteData && this.props.location.state.siteData.whatsAppBuyer != null ?
                this.props.location.state.siteData.whatsAppBuyer : true,
            emailToBuyer: this.props.location.state.siteData && this.props.location.state.siteData.emailAppBuyer != null ?
                this.props.location.state.siteData.emailAppBuyer : true,
            feedBackToBuyer: this.props.location.state.siteData && this.props.location.state.siteData.feedBackToBuyer != null ?
                this.props.location.state.siteData.feedBackToBuyer : false,
            inventory: this.props.location.state.siteData && this.props.location.state.siteData.inventory != null ?
                this.props.location.state.siteData.inventory : false,
            festivalBanners: this.props.location.state.siteData && this.props.location.state.siteData.festivalBanners != null ?
                this.props.location.state.siteData.festivalBanners : false,
            diningSetup: this.props.location.state.siteData && this.props.location.state.siteData.diningSetup != null ?
                this.props.location.state.siteData.diningSetup : false,
            themedesign: this.props.location.state.siteData && this.props.location.state.siteData.themeDesign != null ?
                this.props.location.state.siteData.themeDesign : "THEME1",
            frontSite: this.props.location.state.siteData && this.props.location.state.siteData.frontSite != null ?
                this.props.location.state.siteData.frontSite : true,
            isCustomWhatsapp: this.props.location.state.siteData && this.props.location.state.siteData.isCustomWhatsapp != null ?
                this.props.location.state.siteData.isCustomWhatsapp : false,
            diningSettingMenu: this.props.location.state.siteData && this.props.location.state.siteData.diningSettingMenu != null ?
                this.props.location.state.siteData.diningSettingMenu : '',
            siteMode: this.props.location.state.siteData && this.props.location.state.siteData.siteMode != null ?
                this.props.location.state.siteData.siteMode : 'LIVE',
            selectedRowKeys: [],
            transaction: [],
            data: [],
            pages: [],
            masterSer: undefined,
            partnerType:this.props.location.state.siteData && this.props.location.state.siteData.partnerType ?  this.props.location.state.siteData.partnerType :  undefined,
            channelPartner:this.props.location.state.siteData && this.props.location.state.siteData.channelPartner ?  this.props.location.state.siteData.channelPartner :  undefined,
            channelPartnerData: null,
            salesPerson:this.props.location.state.siteData && this.props.location.state.siteData.salesPerson ?  this.props.location.state.siteData.salesPerson :  undefined,
            supportAccManager:this.props.location.state.siteData && this.props.location.state.siteData.supportAccManager ?  this.props.location.state.siteData.supportAccManager :  undefined,
            specTypeDisplay: [],
            specStatus: [],
            oneTimeService: [],
            fullImg: false,
            fullImage: "",
            previewVisible: false,
            siteLogo: {},
            recData: null,
            invoiceModal: false,
            invoiceRecord: null,
            iconImg:
                this.props.location.state.siteData.siteLogo != null ? {
                    key: this.props.location.state.siteData.siteLogo.key,
                    uid: this.props.location.state.siteData.siteLogo.key,
                    bucket: this.props.location.state.siteData.siteLogo.bucket,
                    region: this.props.location.state.siteData.siteLogo.region,
                    url: `${S3_Url}${this.props.location.state.siteData.siteLogo.key}`
                } : {},
            //  {
            //     key: "internalAdmin/158c7f90-0c7c-11ec-bc70-67361e28b8d5",
            //     uid: "internalAdmin/158c7f90-0c7c-11ec-bc70-67361e28b8d5",
            //     bucket: "btoc-ecom-prod",
            //     region: "ap-south-1",
            //     url: `${S3_Url}internalAdmin/158c7f90-0c7c-11ec-bc70-67361e28b8d5`

            // },

            removedIconImg: false,
            resizedIcon: [],
            siteOptionVal: this.props.location.state.siteData && this.props.location.state.siteData.siteOptions,
            // "COMMERCE",
            paymentOption: [],
            showText: false,
            loading: false,
            courierOption: this.props.location.state.siteData.courierOption == "SHIPROCKET" ? "SHIPROCKET" : "SELF",
            photo: this.props.location.state.siteData.no_of_photos != null ? true : false,
            displayPLP: this.props.location.state.siteData.siteOptions != null ? true : false,
            validFrom: this.props.location.state.siteData.fromDate,
            validTo: this.props.location.state.siteData.tillDate,
            searched: false,
            data11: [],
            tags: [
                { text: 'Contacts', value: 'CONTACTS' },
                { text: 'Contents', value: 'CONTENTS' },
                { text: 'Custom', value: 'CUSTOM' },
                { text: 'Features', value: 'FEATURES' },
                { text: 'Footers', value: 'FOOTERS' },
                { text: 'Form', value: 'FORM' },
                { text: 'Headers', value: 'HEADERS' },
                { text: 'Pricing', value: 'PRICING' },
                { text: 'Teams', value: 'TEAMS', },
                { text: 'Testimonials', value: 'TESTIMONIALS' },
                { text: 'Call to Action', value: 'CallToAction' },
                { value: 'COVERS', text: 'Covers' },
                { value: 'BLOG', text: 'Blog' },
                { value: 'ECOMMERCE', text: 'E-commerce' },
                { value: 'MAGAZINE', text: 'Magazine' },
                { value: 'PROJECTS', text: 'Projects' },
                { value: 'COUNTERS', text: 'Counters' },
                { value: 'DOWNLOAD', text: 'Download' },
                { value: 'EXPLORE', text: 'Explore' },
                { value: 'FAQ', text: 'FAQ' },
                { value: 'GALLERY', text: 'Gallery' },
                { value: 'MAPS', text: 'Maps' },
                { value: 'CUSTOMERS', text: 'Customers' },
                { value: 'PORTFOLIO', text: 'Portfolio' },
                { value: 'STEPS', text: 'Steps' },
                { value: 'SERVICE', text: 'Service' },
                { value: 'SIGNUP', text: 'Sign up' },
                { value: 'SUBSCRIBE', text: 'Subscribe' },
                { value: 'VIDEO', text: 'Video' },
                { value: 'ADMIN', text: 'Admin' },
                { value: 'INDUSTRY', text: 'Industry' },
                { value: 'POLICIES', text: 'Policies' },
                { value:'SINGLE_PAGE_SITE', text:'Single Page Site'},
                { value: 'LANDING_PAGE', text: 'Landing Page' }



            ],
            paymentModes: this.props.location.state.siteData && this.props.location.state.siteData.paymentModes ? this.props.location.state.siteData.paymentModes.filter((mode) => {
                if(mode.__typename){
                    delete mode.__typename
                }
                return mode
            }) : [],
            displayName: "",
            internalName: "",
            paymentState: "",
            paymentModesAvailable: [],
            isPaymentModesTouched: false,
            paymentOption: this.props.location.state && this.props.location.state.siteData && this.props.location.state.siteData.integratePaymentOption ? this.props.location.state.siteData.integratePaymentOption : [],
            showWhatsAppConfig: false,
            groupId: this.props.location.state && this.props.location.state.siteData && this.props.location.state.siteData.groupId ? this.props.location.state.siteData.groupId : null,
            isGroupReward: false,
            groupRewardExist: false,
        }
    }


    runQuery = (nextToken, limit) => {
        // console.log(nextToken, limit)
        this.props.client.query({
            query: getSitePagesByAdmin,
            fetchPolicy: 'network-only',
            variables: {
                nextToken: nextToken,
                limit: limit
            }
        })
            .then(({ data }) => {
                if (data && data.getSitePagesByAdmin) {
                    // console.log("data...getSitePagesByAdmin", data)
                    let data1 = this.state.data.concat(data.getSitePagesByAdmin.items)
                    // console.log("data...full", data1)
                    let nextToken1 = data.getSitePagesByAdmin.nextToken
                    this.setState({
                        data: data1
                    }, () => {
                        if (nextToken1 != null && nextToken1 != undefined && nextToken1 != '') {
                            this.runQuery(nextToken1, 70)
                        }
                    })
                }

            })
            .catch(err => {
                console.log(`Error : ${JSON.stringify(err)}`)

            })
    }

    componentDidMount() {
        // console.log("sadf ::", this.props.location.state.siteData)
        this.setPaymentModesAvailable()
        this.runQuery('', 70)
        this.getSiteConfig()
        this.getServiceCharges()
        this.getRecharge()
        this.setIsGroupReward()
        this.getUsers()
        let arr = this.state.tags
        arr.sort(function (a, b) {
            var nameA = a.text.toLowerCase(), nameB = b.text.toLowerCase();
            if (nameA < nameB)
                return -1;
            if (nameA > nameB)
                return 1;
            return 0;
        });
        this.setState({
            tags: arr
        })
    }

    // static getDerivedStateFromProps(nextProps) {
    //     if (nextProps.data.getSitePagesBySiteId) {
    //         return {
    //             data: nextProps.data.getSitePagesBySiteId.items,
    //         }
    //     }
    //     return null
    // }
    getUsers=()=>{
        this.props.client.query({
            query: getInternalUsers,
            fetchPolicy: 'no-cache'
        })
            .then(({ data }) => {
                let record;
                if (this.state.channelPartner) {
                    let users = data.getInternalUsers
                     users && users.filter((ele) => {
                        if (ele.userRole == 'CP' && ele.companyName && ele.companyName == this.state.channelPartner) {
                            record = ele
                        }
                    })
                }

               

                this.setState({
                    userData: data.getInternalUsers,
                    channelPartnerData: record,
                    loading: false
                })
            })
    }
    static setPage = (data) => {
        // console.log("data...",data)
    }

    setIsGroupReward = () => {
        // return
        this.props.client.query({
            query: getSiteRewardSettingByDomain,
            fetchPolicy: 'network-only',
            variables: {
                domain: this.props.location.state.siteData.fqdn
            }
        })
            .then(({ data }) => {
                // console.log("data :::", data.getSiteRewardSettingByDomain)
                if (data && data.getSiteRewardSettingByDomain && data.getSiteRewardSettingByDomain.onGroup) {
                    if (data.getSiteRewardSettingByDomain.onGroup) {
                        this.setState({ isGroupReward: true, groupRewardExist: true })
                    } else {
                        this.setState({ groupRewardExist: true })
                    }
                }
            })
            .catch(err => {
                console.log(`Error : ${err}`)

            })
    }

    setPaymentModesAvailable = () => {
        let paymentModesAvailable = [
            {
                internalName: "CASH",
                disabled: false,
                paymentState: "PAID_OFFLINE",
                // displayName: "CASH"
            },
            {
                internalName: "CREDIT_CARD",
                disabled: false,
                paymentState: "PAID_ONLINE",
                // displayName: "CREDIT CARD",
            },
            {
                internalName: "DEBIT_CARD",
                disabled: false,
                paymentState: "PAID_ONLINE",
                // displayName: "DEBIT CARD",
            },
            {
                internalName: "NETBANKING",
                disabled: false,
                paymentState: "PAID_ONLINE",
                // displayName: "NETBANKING"
            },
            {
                internalName: "CCAVENUE",
                disabled: false,
                paymentState: "PAID_ONLINE",
                // displayName: "RAZORPAY"
            },
            {
                internalName: "PAYTM_EDC",
                disabled: false,
                paymentState: "PAID_ONLINE",
                // displayName: "RAZORPAY"
            },
            {
                internalName: "PAYTM",
                disabled: false,
                paymentState: "PAID_ONLINE",
                // displayName: "RAZORPAY"
            },
            {
                internalName: "RAZORPAY",
                disabled: false,
                paymentState: "PAID_ONLINE",
                // displayName: "RAZORPAY"
            },
            {
                internalName: "PAYPAL",
                disabled: false,
                paymentState: "PAID_ONLINE",
                // displayName: "PAYPAL"
            },
            {
                internalName: "UPI",
                disabled: false,
                paymentState: "PAID_ONLINE",
                // displayName: "UPI"
            },
            {
                internalName: "PAYMENT_BTN",
                disabled: false,
                paymentState: "PAID_ONLINE",
                // displayName: "PAYMENT BUTTON",
            },
            {
                internalName: "AGGREGATOR_PAYMENT",
                disabled: false,
                paymentState: "PAID_ONLINE",
                // displayName: "AGGREGATOR PAYMENT",
            },
            {
                internalName: "MOBILE_MONEY",
                disabled: false,
                paymentState: "PAID_ONLINE",
                // displayName: "MOBILE MONEY",
            },
            {
                internalName: "VOUCHER",
                disabled: false,
                paymentState: "PAID_ONLINE",
                // displayName: "VOUCHER"
            },
            {
                internalName: "SWISH",
                disabled: false,
                paymentState: "PAID_ONLINE",
                // displayName: "SWISH"
            },
            {
                internalName: "WALLET",
                disabled: false,
                paymentState: "PAID_ONLINE",
                // displayName: "WALLET"
            },
            {
                internalName: "BANK_TRANSFER",
                disabled: false,
                paymentState: "PAID_ONLINE",
                // displayName: "BANK TRANSER"
            },
            {
                internalName: "CHEQUE",
                disabled: false,
                paymentState: "PAID_OFFLINE",
                // displayName: "CHEQUE"
            },
            {
                internalName: "CARD",
                disabled: false,
                paymentState: "PAID_ONLINE",
                // displayName: "CHEQUE"
            },
            {
                internalName: "WRITE_OFF",
                disabled: false,
                paymentState: "PAID_ONLINE",
                // displayName: "CHEQUE"
            },
            {
                internalName: "DINEOUT",
                disabled: false,
                paymentState: "PAID_ONLINE",
            },
            {
                internalName: "EAZYDINER",
                disabled: false,
                paymentState: "PAID_ONLINE",
            },
            {
                internalName: "SWIGGY_PAY",
                disabled: false,
                paymentState: "PAID_ONLINE",
            },
            {
                internalName: "ZOMATO_PAY",
                disabled: false,
                paymentState: "PAID_ONLINE",
            },
            {
                internalName: "BENEFIT",
                disabled: false,
                paymentState: "PAID_ONLINE",
            },
            {
                internalName: "BRFC_PAYMENT",
                disabled: false,
                paymentState: "PAID_ONLINE",
            },
            {
                internalName: "BRFC_DEBIT",
                disabled: false,
                paymentState: "PAID_ONLINE",
            },
            {
                internalName: "AMEX_CARD",
                disabled: false,
                paymentState: "PAID_ONLINE",
            },
            {
                internalName: "CASH_CARD",
                disabled: false,
                paymentState: "PAID_ONLINE",
            },
        ]

        if(this.state && this.state.paymentModes && this.state.paymentModes.length && this.state.paymentModes.length > 0){
            this.state.paymentModes.map((mode) => {
                paymentModesAvailable.map((availableMode) => {
                    if(availableMode.internalName == mode.internalName){
                        availableMode.disabled = true
                    }
                })
            })
        }

        this.setState({paymentModesAvailable})
    }

    handleColorClose = () => {
        this.setState({ bg: "#FFFFFF", showPicker: false })
    }
    handleBgClose = (val) => {
        this.setState({ showPicker: false })
    }
    handleChangeComplete = (color) => {
        this.setState({ bg: color.hex });
    };
    handleImgClick = () => {
        this.setState({ showPicker: true })
    }

    handleColorMenuClose = () => {
        this.setState({ menuColor: "#ea2b1f", showMenu: false })
    }
    handleMenuClose = (val) => {
        this.setState({ showMenu: false })
    }
    handleMenuComplete = (color) => {
        this.setState({ menuColor: color.hex });
    };
    handleMenuClick = () => {
        this.setState({ showMenu: true })
    }
    menuClose = () => {
        this.setState({ menuColor: "#ea2b1f", showMenu: false })
    }
    handleTextClick = () => {
        this.setState({ showText: true })
    }
    handleChangeText = (color) => {
        this.setState({ textColor: color.hex });
    };
    handleTextClose = (val) => {
        this.setState({ showText: false })
    }
    textClose = () => {
        this.setState({ textColor: "#000000", showText: false })
    }
    handleFontStyle = (val) => {
        this.setState({ fontStyle: val })
    }

    whatsappToSeller = (e) => {
        this.setState({
            whatsappToSeller: e.target.value,
        });
    }
    whatsappToBuyer = (e) => {
        this.setState({
            whatsappToBuyer: e.target.value,
        });
    }
    emailToBuyer = (e) => {
        this.setState({
            emailToBuyer: e.target.value,
        });
    }
    feedBackToBuyer = (e) => {
        this.setState({
            feedBackToBuyer: e.target.value,
        });
    }
    inventory = (e) => {
        this.setState({
            inventory: e.target.value,
        });
    }
    festivalBanners = (e) => {
        this.setState({
            festivalBanners: e.target.value,
        });
    }
    diningSetup = (e) => {
        this.setState({
            diningSetup: e.target.value,
        });
    }
    siteMode = (e) => {
        this.setState({
            siteMode: e.target.value,
        });
    }
    themedesign = (e) => {
        this.setState({
            themedesign: e.target.value,
        });
    }
    frontSite = (e) => {
        this.setState({
            frontSite: e.target.value,
        });
    }
    isCustomWhatsapp = (e) => {
        this.setState({
            isCustomWhatsapp: e.target.value,
        });
    }
    showWhatsAppConfigFunction = (e) => {
        this.setState({
            showWhatsAppConfig: true,
        });
    }
    handleWhatsAppConfigClose = (state) => this.setState({ showWhatsAppConfig: state })

    handleCancel = () => this.setState({ previewVisible: false })

    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    }

    beforeUpload = (file) => {
        var fr = new FileReader;

        fr.onload = () => { // file is loaded
            var img = new Image;
            img.onload = () => {
                if (img.width != 600 || img.height != 200) {
                    this.setState({
                        siteLogoErr: true
                    })
                    this.props.form.setFields({
                        siteLogo: {
                            value: this.state.siteLogo,
                            errors: [new Error('Please Upload image with (600 px * 200 px) dimensions.')]
                        }
                    })
                    return false
                } else {
                    this.setState({
                        siteLogoErr: false
                    })
                }
            };

            img.src = fr.result; // is the data URL because called with readAsDataURL

        };
        fr.readAsDataURL(file); // I'm using a <input type="file"> for demonstrating
    }
    handleMainImageRemove = (file) => {
        this.setState({ iconImg: {}, removedIconImg: true })
        return;
    }

    handleMainImage = ({ file }) => {
        this.setState((prevState, props) => {
            if (Object.keys(prevState.iconImg).length === 0 && prevState.removedIconImg === false) {
                return {
                    iconImg: file,
                    removedIconImg: false
                }
            }
            else {
                return {
                    // iconImg: {},
                    removedIconImg: false
                }
            }

        }, this.imageResize1(file));

    }

    imageResize1 = (img) => {
        let obj = {};
        let arr = []
        // console.log("Inresize",img)
        // return
        try {
            Resizer.imageFileResizer(
                img.originFileObj,
                1000,
                1000,
                "WEBP",
                80,
                0,
                (uri) => {
                    // console.log("Aft resize",uri)
                    obj = {
                        name: img.name,
                        size: uri.size,
                        type: uri.type,
                        uid: img.uid,
                        url: uri,
                    }
                    // console.log("After Resize", uri,obj);
                    arr.push(obj)

                    this.setState({

                        resizedIcon: arr,
                    });
                },
                "blob"
            );
            //  }
        }
        catch (err) {
            console.log("err", err)
        }

    };

    handleSelectChange = (value) => {
        if (value === 'SIMPLE_B2C') {
            this.setState({
                displayPLP: true

            })
            //   this.props.form.resetFields(['templateName'])
        } else {
            this.setState({
                displayPLP: false
            })
        }
        if (value === 'MARKETING') {
            this.setState({
                photo: true
            })
            //   this.props.form.resetFields(['templateName'])
        } else {
            this.setState({
                photo: false
            })
        }
    }

    courierOption = (e) => {
        this.setState({
            courierOption: e.target.value,
        });
    }


    enterLoading = () => {
        this.setState({ loading: true })
    }

    onChangePayment = (val) => {
        this.setState({
            paymentOption: val,
        });
    }

    messsage = () => {
        this.props.history.push('/')
    }
    handleMultipleMenu = (e) => {
        console.log("ee", e.target.value)
        // this.setState({
        //     diningSettingMenu: e.target.value
        // })
    }

    handleSiteOption = (value) => {
        if (value === 'ENQUIRY') {
            this.setState({
                displayPay: true
            })
        } else {
            this.setState({
                displayPay: false
            })
        }

    }
    walletchange = (e) => {
        this.setState({
            wallet: e.target.value,
        });
    }
    isWebAppEnabled = (e) => {
        this.setState({
            isWebAppEnabled: e.target.value,
        });
    }
    callback=(key)=> {
        console.log("key",key);
        if( key === "3"){
            this.getTransactions()
        }
        if( key === "1"){
            this.getSiteConfig()
        }
      }


    getSiteConfig = () => {
        this.props.client.query({
            query: getSiteConfig,
            variables: {
                domain: this.props.location.state.siteData.fqdn
            },
            fetchPolicy: 'network-only'
        })
            .then(({ data }) => {
                // console.log('getSiteConfig ::', data)
                let ms = []
                let recData = []
                let arr = data.getSiteConfig != null ? data.getSiteConfig : []
                arr.siteServices.map((vel)=>{
                    ms.push(vel.serviceName)
                    recData.push(vel)
                })
                // console.log("recData::",recData)
                this.setState({
                    serviceData: data.getSiteConfig,
                    masterSer: ms,
                    recData: recData,
                    loading: false
                })
            })
            .catch(err => {
                console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(err, null, 2)}`)
            })
    }
    getServiceCharges = () => {
        this.props.client.query({
            query: getSiteConfigServices,
            variables: {
                domain: "recaho"
            },
            fetchPolicy: 'network-only'
        })
            .then(({ data }) => {
                // console.log('dataa', data)
                this.setState({
                    allService: data.getSiteConfigServices,
                    loading: false
                })
            })
            .catch(err => {
                console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(err, null, 2)}`)
            })
    }

    updateRewardSettings = (variables) => {
        // console.log("variables :::", variables)
        // return;
        this.props.updateSiteRewardSetting({
            variables
          })
        .then(({ data }) => {
          console.log('updateSiteRewardSettingV2', data.updateSiteRewardSettingV2)
        })
        .catch(err => {
          console.log(`Catch: handleFormSubmit: error: ${err}`)
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.props.form.validateFields(["ownerMob",'homeTitle','addressLineOne','addressLineTwo','city','state','zip','country',"ownerEmail","custName","companyName","siteType","charges","availableWhatsApp","metaDescription","latitude", "longitude","isDayClosureEnabled", "isGroupReward", "isCounterEnabled", "isReturnOrderEnabled","isDemoSite","additionalContactNumbers","htmlPrint","dashboardRangeEnabled","showSupportNumber","newOrderAppEnabled","isManualDayClosure","isCustOnGroupEnabled", "isOrderTimelineEnabled","isMasterKdsEnabled"],(err, values) => {
            if (values.no_of_photos === undefined || values.no_of_photos == null) {
                delete values.no_of_photos
            }
            if (values.gstNo === null || values.gstNo === "") {
                delete values.gstNo
            }
             
                //   return
            if (!err) {

                this.enterLoading();
                let LatLong = {}
                if(values.latitude && values.longitude){
                    LatLong={
                        lat:values.latitude,
                        long: values.longitude
                    }
                }
                let footerAddress = {
                    addressLineOne: values.addressLineOne ? values.addressLineOne : undefined,
                    addressLineTwo: values.addressLineTwo ? values.addressLineTwo : undefined,
                    city: values.city ? values.city : undefined,
                    state: values.state ? values.state : undefined,
                    zip: values.zip ? values.zip : undefined,
                    country: values.country ? values.country : undefined,
                    location: LatLong && Object.keys(LatLong).length != 0 ? LatLong : undefined,

                }

                let imgobj = null

                if (this.state.iconImg != null && Object.keys(this.state.iconImg).length != 0) {

                    if (Object.keys(this.state.iconImg).length !== 0 && !this.state.iconImg.hasOwnProperty("key")) {
                        imgobj = {
                            bucket: bucket,
                            region: S3_REGION,
                            localUri: this.state.resizedIcon[0].url,
                            key: `internalAdmin1/${uuidv1()}`,
                            mimeType: this.state.resizedIcon[0].type

                        };

                    }
                    else {
                        imgobj = {
                            key: this.state.iconImg.key,
                            bucket: this.state.iconImg.bucket,
                            region: this.state.iconImg.region
                        }

                    }
                }
                
                // console.log("footerAddress",footerAddress,values.latitude , values.longitude,LatLong)
                // return false
                let variableObject = {
                    domain: this.props.location.state.siteData.domain,
                    fqdn: this.props.location.state.siteData.fqdn,
                    siteId: this.props.location.state.siteData.siteId,
                    internalName: this.props.location.state.siteData.internalName,
                    siteType: values.siteType,
                    siteOptions: this.props.location.state.siteData.siteOptions,
                    integratePaymentOption: this.state.paymentOption.length != 0 ? this.state.paymentOption : [],
                    contactEmail: values.ownerEmail,
                    contactNumber: values.ownerMob,
                    additionalContactNumbers: values.additionalContactNumbers,
                    no_of_products: values.no_of_products,
                    no_of_photos: values.no_of_photos,
                    custName: values.custName,
                    companyName: values.companyName,
                    gstNo: values.gstNo,
                    fromDate: this.state.validFrom,
                    tillDate: this.state.validTo,
                    courierOption: this.state.courierOption,
                    homePageTitle: values.homeTitle,
                    homePageMetadesc: values.metaDescription,
                    siteLogo: imgobj,
                    enabled: true,
                    footerAddress: footerAddress,
                    backgroundColor: this.state.bg,
                    menuFontColor: this.state.menuColor,
                    textFontColor: this.state.textColor,
                    textFontType: this.state.fontStyle,
                    whatsAppSeller: this.state.whatsappToSeller,
                    whatsAppBuyer: this.state.whatsappToBuyer,
                    emailAppBuyer: this.state.emailToBuyer,
                    feedBackToBuyer: this.state.feedBackToBuyer,
                    inventory: this.state.inventory,
                    frontSite: this.state.frontSite,
                    diningSetup: this.state.diningSetup,
                    isWalletEnabled: this.state.wallet,
                    isWebAppEnabled: this.state.isWebAppEnabled,
                    diningSettingMenu: values.diningSetupMenu ? values.diningSetupMenu : null,
                    themeDesign: this.state.themedesign,
                    siteMode: this.state.siteMode,
                    availableWhatsApp: values.availableWhatsApp,
                    festivalBanners: this.state.festivalBanners,
                    isCustomWhatsapp: this.state.isCustomWhatsapp,
                    isDayClosureEnabled: values.isDayClosureEnabled,
                    groupId: this.state.groupId,
                    salesPerson: this.state.salesPerson,
                    supportAccManager: this.state.supportAccManager,
                    channelPartner: this.state.channelPartner,
                    stockistName: values.stockistName,
                    isCounterEnabled: values.isCounterEnabled,
                    isReturnOrderEnabled: values.isReturnOrderEnabled,
                    dashboardRangeEnabled: values.dashboardRangeEnabled,
                    newOrderAppEnabled: values.newOrderAppEnabled,
                    isManualDayClosure: values.isManualDayClosure,
                    isCustOnGroupEnabled: values.isCustOnGroupEnabled,

                    htmlPrint: values.htmlPrint,
                    isDemoSite: values.isDemoSite,
                    partnerType: this.state.partnerType,
                    supportNumber: values.showSupportNumber ? this.state.channelPartnerData && this.state.channelPartnerData.supportNumber : null,
                    premiumFeature: {
                        isOrderTimelineEnabled: values.isOrderTimelineEnabled || null,
                        isMasterKdsEnabled: values.isMasterKdsEnabled || null
                    }
                }
             
                // console.log("variableObject:",variableObject)
                if(this.state.isPaymentModesTouched){ // && this.state.paymentModes && this.state.paymentModes != [] && this.state.paymentModes.length && this.state.paymentModes.length > 0
                    variableObject.paymentModes = this.state.paymentModes
                }
                // console.log("this.state.groupRewardExist :::", this.state.groupRewardExist, values.isGroupReward)
                if(this.state.groupRewardExist){
                    if(this.state.isGroupReward != values.isGroupReward){
                        const rewardObject = {
                            onGroup: values.isGroupReward,
                            isEnabled: true,
                            groupId: this.state.groupId,
                            domain: this.props.location.state.siteData.fqdn,
                            siteId: this.props.location.state.siteData.siteId,
                        }
                        this.updateRewardSettings(rewardObject)
                    }
                } else {
                    if(values.isGroupReward){
                        const rewardObject = {
                            isEnabled: true,
                            onGroup: true,
                            groupId: this.state.groupId,
                            domain: this.props.location.state.siteData.fqdn,
                            siteId: this.props.location.state.siteData.siteId,
                        }
                        this.updateRewardSettings(rewardObject)
                    }
                }

                // console.log("variableObject",variableObject)
                //  return
                this.props.editSitMutate({
                    variables: variableObject
                }).then(({ data }) => {
                    if (data.updateSiteByAdmin) {
                        if (this.state.pages.length > 0) {
                            this.submitPages(this.props.location.state.siteData.siteId)
                        }
                        // if (this.state.wallet && values.charges && values.charges.length > 0) {
                        //     // console.log("changes ::", values.charges)
                        //     this.updateChargeModal(values.charges)
                        // }
                        if ((!this.props.location.state.siteData.isWalletEnabled && this.state.wallet) && (this.state.serviceData == null)) {
                            this.createSiteWallet()
                        }
                        message.success("Saved Successfully");
                        this.createLogs()
                        // this.setState({
                        //     loading: false
                        // })
                        this.props.history.push("/");
                    }
                }).catch(res => {
                    this.setState({
                        loading: false
                    })
                    console.log(
                        `Catch: handleFormSubmit: error: `, res
                    );
                });
            } else {
                console.log("Error", err)
            }
        })
    }



    submitPages = (siteId) => {
        let pagesData = []
        this.state.pages.map((val, i) => {
            let img = {}
            if (val.image != null) {
                let obj = { ...val.image }
                delete obj.__typename
                img = obj
            }
            else {
                img = null
            }
            let pageData = {
                siteId: siteId,
                pageName: val.pageName,
                displayName: val.displayName,
                value: val.value,
                onFooter: val.onFooter,
                onHome: val.onHome,
                displayOrder: i,
                pageFor: val.pageFor,
                pageType: val.pageType,
                tags: val.tags,
                image: img
            }
            pagesData.push(pageData)
        })
        // return
        this.enterLoading()
        this.props.sitePages({
            variables: {
                input: pagesData
            }
        }).then(({ data }) => {
            // console.log("data..",data)
            this.setState({
                selectedRowKeys: [],
                pages: []
            })
        })
    }

    updateChargeModal = (data) => {
        // console.log("In updateChargeModal ::", data)
        let chargeData = data && data.length > 0 ? data : []
        let newCharge = []
        chargeData.map((val) => {
            let obj = {
                charges: parseInt(val.charges * 100),
                chargingModel: val.chargingModel,
                masterStatus: val.masterStatus,
                serviceName: val.serviceName,
                startedAt: val.startedAt,
                status: val.status,
                startDate: val.startDate ? moment(val.startDate).format("YYYY-MM-DD") : undefined,
                endDate: val.startEnd ? moment(val.startEnd).format("YYYY-MM-DD") : undefined,
                // endDate: (val.chargingModel == "PAY_PER_USE" && (val.status == 'TRIAL' || val.status == 'FREE' || val.status == 'INACTIVE')) ? moment(val.startEnd).format("YYYY-MM-DD") : (val.chargingModel == "MONTHLY" || val.chargingModel == "YEARLY") ? moment(val.startDate).add(val.chargingModel == "MONTHLY" ? 1 : 12, "M").format("YYYY-MM-DD") : undefined,
                updatedAt: val.updatedAt
            }
            newCharge.push(obj)
        })
        // console.log("new Data ::", newCharge)
        let arrayFilter = newCharge.filter(val => val.chargingModel != undefined)
        // console.log("new filter ::",arrayFilter)
        // return
        if(arrayFilter && arrayFilter.length > 0){
        this.props.client.mutate({
            mutation: this.state.serviceData != null ? updateSiteConfig : createSiteConfig,
            variables: {
                domain: this.props.location.state.siteData.fqdn,
                siteId: this.props.location.state.siteData.siteId,
                siteServices: arrayFilter,
                enabled: this.props.location.state.siteData.enabled
            }
        }).then(({ data }) => {
            console.log("Resp SiteConfig ::", data)

        })
        }else{
            console.log("Service error")
            return
        }
    }

    createSiteWallet = () => {
        // console.log("Data ::",data)
        this.props.client.mutate({
            mutation: createSiteWallet,
            variables: {
                siteId: this.props.location.state.siteData.siteId,
                balance: 0,
                currency: this.props.location.state.siteData.curr,
                domain: this.props.location.state.siteData.fqdn
            }
        }).then(({ data }) => {
            console.log("Resp SiteConfig ::", data)

        })
    }
    createLogs=async()=>{
        let obj = {
            siteId: this.props.location.state.siteData.siteId,
            eventName: "Setup",
            eventType: 'AUTO_GENERATE',
            note: "Edit Site"
        }
        let resp = await SiteActionEvent(this.props,obj)
      if(resp){
        // message.success("Notes added successfully")
      }
    }
    onChangeHandle = (selectedRowKeys, selectedRows) => {
        // console.log("sel...",selectedRows,selectedRowKeys)
        // console.log("pages..",this.state.pages)
        // console.log("selectedRowKeys..",this.state.selectedRowKeys)

        if (!this.state.searched) {
            this.setState({
                pages: selectedRows,
                selectedRowKeys: selectedRowKeys,
            }, () => {
                // console.log("...", this.state.pages, this.state.selectedRowKeys)
            })
        }
        else
            if (this.state.searched && selectedRowKeys.length < this.state.selectedRowKeys.length) {
                var filteredKeywords = this.state.selectedRowKeys.filter((word) => !selectedRowKeys.includes(word));
                // console.log("remo...",filteredKeywords)
                if (filteredKeywords.length > 0) {
                    var arr = this.state.pages.filter(function (el) {
                        return el.id !== filteredKeywords[0];
                    });
                    this.setState({
                        pages: arr,
                        selectedRowKeys: selectedRowKeys,
                    }, () => {
                        // console.log("... remove", this.state.pages, this.state.selectedRowKeys)
                    })
                }
            }
            else
                if (this.state.searched && selectedRows.length == selectedRowKeys.length) {
                    this.setState({
                        pages: selectedRows,
                        selectedRowKeys: selectedRowKeys,
                    }, () => {
                        // console.log("...selected add", this.state.pages, this.state.selectedRowKeys)
                    })
                }
                else
                    if (this.state.searched && selectedRowKeys.length > this.state.selectedRowKeys.length) {
                        let nArray = this.state.pages.concat(selectedRows)
                        // console.log("nArray nArray",nArray)
                        const arrayUniqueByKey = [...new Map(nArray.map(item =>
                            [item['id'], item])).values()];
                        // console.log("nArray",arrayUniqueByKey,selectedRowKeys)

                        this.setState({
                            pages: arrayUniqueByKey,
                            selectedRowKeys: selectedRowKeys,
                        }, () => {
                            // console.log("...selected add if add then search add", this.state.pages, this.state.selectedRowKeys)
                        })
                    }
                    else

                        if (selectedRows.length == 0 && selectedRowKeys.length == 0) {
                            this.setState({
                                pages: selectedRows,
                                selectedRowKeys: selectedRowKeys,
                            })
                        }

    }

    tableOnchange = (pagination, filters) => {
        // this.setState({
        //     defaultFilter:filters.pageName
        // })
    }

     handleKeyDown = (event) => {
        if ( event.key !== 'Backspace' &&  event.key !== 'Delete' && !/[0-9\b]/.test(event.key)) {
          event.preventDefault();
        }
        if (event.target.value.length >= 10 && event.key !== 'Backspace' && event.key !== 'Delete') {
            event.preventDefault();
          }
      };

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    }

    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({ searchText: '' });
    }


    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys, selectedKeys, confirm, clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => { this.searchInput = node; }}
                    placeholder={`Search Page Name`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}

                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button
                    onClick={() => this.handleReset(clearFilters)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: (text) => (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        ),
    })

    onchangeSearch = (val) => {
        if (val != '' && val != null) {
            let vall = val.toLowerCase()
            // console.log("vall..",vall)
            let arr = this.state.data.filter((data) => {
                if (data.value && data.value.toLowerCase().includes(vall) || data.pageName && data.pageName.toLowerCase().includes(vall)) {
                    return data
                }
            })
            this.setState({
                data11: arr,
                searched: true
            })

        }
        else {
            this.setState({
                searched: false,
                data11: []
            })
        }
    }
    handleCancel=()=>{
        this.setState({
          visibleM: false
      })
      }

    modalChange=()=>{
        this.setState({
          visibleM: true,
        })
      }
      getRecharge=()=>{
        //   console.log("In getRecharge")
        this.props.client.query({
          query: getSiteWalletById,
          variables: {
              siteId: this.props.location.state.siteData.siteId
          },
          fetchPolicy: 'no-cache'
        })
          .then(({ data }) => {
            // console.log('dataa', data)
            this.setState({
              currentB: data.getSiteWalletById,
              loading: false
            })
          })
      }

      getTransactions=()=>{
        //   console.log("In Transactions")
          this.enterLoading()
        this.props.client.query({
          query: getSiteTransactionsByCreatedAt,
          variables: {
              siteId: this.props.location.state.siteData.siteId,
              type: "CREDIT",
              limits: 50,
              skips: 0
          },
          fetchPolicy: 'no-cache'
        })
          .then(({ data }) => {
            // console.log('dataa Transactions', data)
            let trData = data.getSiteTransactionsBySite
            let sorted = trData.sort(function(a, b) {
                return b.createdAt - a.createdAt;
            });
            this.setState({
            transaction: sorted,
              loading: false
            })
          })
      }
      
      handleOk=(e)=>{
        // console.log("on ok")  
        e.preventDefault();
          this.props.form.validateFields(["amount","note"],(err, values) => {
            // console.log("values",values,this.props.siteResp)
            // return
              if(!err){
                this.setState({
                  paymentLoading: true
                })
        this.props.client.mutate({
          mutation: siteWalletRechargeByAdmin,
          variables: {
            siteId: this.props.location.state.siteData.siteId,
            amt: parseInt(values.amount*100),
            currency: this.props.location.state.siteData.curr,
            rechargeBy: "Admin",
            note: values.note ? values.note : undefined,
            paymentState: "PAID_OFFLINE"
          }
      }).then(({ data }) => {
          // console.log("data response", data)
          message.success("Recharge Successfull");
          this.props.form.resetFields(['amount'])
          this.props.form.resetFields(['note'])
          this.getRecharge()
          this.getTransactions()
          this.setState({
            paymentLoading: false
          })
      })
    }
    })
      }
    remove = k => {
        const { form } = this.props;
        // can use data-binding to get
        const keys = form.getFieldValue('keys');
        // We need at least one passenger
        if (keys.length === 1) {
            return;
        }

        // can use data-binding to set
        form.setFieldsValue({
            keys: keys.filter(key => key !== k),
        });
    };

    add = () => {
        const { form } = this.props;
        // can use data-binding to get
        const keys = form.getFieldValue('keys');
        const nextKeys = keys.concat(id++);
        // can use data-binding to set
        // important! notify form to detect changes
        form.setFieldsValue({
            keys: nextKeys,
        });
    };


    handleTypeChange = (param, type) => {
        const { form } = this.props;
        // can use data-binding to get
        const keys = form.getFieldValue('keys');

        if (!('value' in this.props)) {
            this.setState({ type });
            dispVal[param] = type
            this.setState({
                specTypeDisplay: dispVal,
                specStatus: dispVal
            });
        }
        this.triggerChange({ type });
    }
    triggerChange = (changedValue) => {
        // Should provide an event to pass value to Form.
        const onChange = this.props.onChange;
        if (onChange) {
            onChange(Object.assign({}, this.state, changedValue));
        }
    }
    showModal = () => {
        this.setState({
            paymentModal: true
        })
    }
    generateTransaction=(record)=>{
        // console.log("generateTransaction ::",record)
        // return
        this.enterLoading()
        this.props.client.mutate({
            mutation: generateTransactionId,
            variables: {
                id: record.id,
            }
        }).then(({ data }) => {
            // console.log("Data ::",data)
            if(data.generateTransactionId){
            this.getTransactions()
            }
        })
    }
    modalInvoice=(record)=>{
        this.setState({
            invoiceModal: true,
            invoiceRecord: record
        })
    }
    handleInvoice=()=>{
        this.setState({
            invoiceModal: false,
            invoiceRecord: null
        },()=>{
            this.getTransactions()
        })
    }
    sendWhatsAppFunction=(link)=>{
        let siteData = this.props.location.state.siteData
        let mobile = siteData.curr == 'INR' ? `+91${siteData.contactNumber}` : siteData.curr == 'GBP' ? `+44${siteData.contactNumber}` : `+91${siteData.contactNumber}`
        let url = `https://${link.bucket}.s3.amazonaws.com/${link.key}`
        this.props.client.mutate({
            mutation: sendWhatsApp,
            variables: {
                type: "MEDIA",
                toNumber: mobile,
                content: "Please find attached your invoice for the payment made. Thank you, and we look forward to working with you",
                imageLink: url,

            }
        }).then(({ data }) => {
            if (data.sendWhatsApp) {
                message.success("Invoice Sent Successfully")
            }
        })
    }
    columns = [
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '10%',
            render:(data)=>{
              var dateString = moment.unix(data).format("DD/MM/YYYY HH:mm");
             
              return(
                  <span>{dateString}</span>
              )
          }
        },{
          title: 'Service',
          dataIndex: 'service',
          key: 'service',
          width: '10%',
        },
        {
            title: 'Transaction Type',
            dataIndex: 'transactionType',
            key: 'transactionType',
            width: '10%',
          },
          {
            title: 'Transaction Amount',
            // dataIndex: 'amount',
            key: 'amount',
            width: '10%',
            render:(data)=>{
              let amoutString = (data.amount/100).toLocaleString(`en-${!(data.currency) && data.currency == 'INR' ? 'IN' : data.currency == 'USD' ? 'US' : 'IN'}`, { style: 'currency', currency: data.currency ? data.currency : "INR"  })
             
              return(
                  <span>{amoutString}</span>
              )
          }
          },
          {
            title: 'Balance',
            width: '10%',
            key: 'Balance',
            render:(data)=>{
              let balanceString = (data.balance/100).toLocaleString(`en-${!(data.currency) && data.currency == 'INR' ? 'IN' : data.currency == 'USD' ? 'US' : 'IN'}`, { style: 'currency', currency: data.currency ? data.currency : "INR" })
             
              return(
                  <span>{balanceString}</span>
              )
          }
          },{
            title: 'Note',
            dataIndex: 'note',
            key: 'note',
            width: '20%',
        },{
            title: 'Invoice',
            width: '10%',
            key: 'Invoice',
            render:(record)=>{
                return(
                    <>
                    {record.attachedInvoice &&  Object.keys(record.attachedInvoice).length != 0 && record.attachedInvoice.invoicePdf &&  Object.keys(record.attachedInvoice.invoicePdf).length != 0  ?
                     <>
                     <a href={`https://${record.attachedInvoice.invoicePdf.bucket}.s3.amazonaws.com/${record.attachedInvoice.invoicePdf.key}`}  target="_blank" >
                        <Tooltip placement="top" title={"Download Invoice"}>
                            <Icon type="download" style={{ fontSize: '15px',marginRight: '5px'}}/>
                        </Tooltip>
                     </a> 
                     <Tooltip placement="top" title={"Send Invoice"}>
                     <Icon type='mail' onClick={()=>this.sendWhatsAppFunction(record.attachedInvoice.invoicePdf,)} style={{ fontSize: '15px',marginRight: '5px'}}/>
                     </Tooltip>
                     <Tooltip placement="top" title={"Regenerate Invoice"}>
                     <Icon type='redo' onClick={()=>this.modalInvoice(record)} style={{ fontSize: '15px',marginRight: '5px'}}/>
                     </Tooltip>
                    </> :
                     <>
                    {record.transactionId == null ?
                        <Icon type='thunderbolt' onClick={()=>this.generateTransaction(record)} />
                    : 
                    <Icon type='form' onClick={()=>this.modalInvoice(record)}/>
                    }
                    </>
                    }
                  </>  
                )
            }
          }
    ];

    handleAddPaymentModes = (internalName, paymentState, displayName) => {
        if(!internalName || internalName == "" || !displayName || displayName == "") {
            message.error("Please Input Payment Mode and Display Name first", 1.6)
            return;
        }
        let paymentModes = this.state.paymentModes, paymentModesAvailable = JSON.parse(JSON.stringify(this.state.paymentModesAvailable));
        console.log("log ::", internalName, displayName)
        paymentModes.push({internalName, displayName, paymentState})
        paymentModesAvailable.forEach((paymentMode) => {
            if(paymentMode.internalName == internalName){
                paymentMode.disabled = true;
            }
        })
        this.setState({paymentModes, paymentModesAvailable, internalName: "", displayName: "", paymentState: "", isPaymentModesTouched: true})
        this.props.form.resetFields(["internalName", "displayName"])
    }

    handleDeletePaymentModes = record => {
        let paymentModes = this.state.paymentModes, paymentModesAvailable = JSON.parse(JSON.stringify(this.state.paymentModesAvailable));
        // console.log(" record: ", record)
        paymentModes = paymentModes.filter((paymentMode) => (paymentMode.internalName != record.internalName && paymentMode.displayName != record.displayName))
        paymentModesAvailable.forEach((paymentMode) => {
            if(paymentMode.internalName == record.internalName){
                paymentMode.disabled = false;
            }
        })
        this.setState({paymentModes, paymentModesAvailable, isPaymentModesTouched: true})
    }   
    
    referenceSite = (value) => {
        this.setState({
            groupId: value,
        });
    }
    partnerTypeChange=(value)=>{
        this.setState({
            partnerType: value
        })
    }
    channelPartnerChange = (value) => {
       
        let record;
        if (value) {
            this.state.userData && this.state.userData.length > 0 && this.state.userData.filter((ele) => {
                if (ele.userRole == 'CP' && ele.companyName && ele.companyName == value) {
                    record = ele
                }
            })
        }

        this.setState({
            channelPartner: value,
            channelPartnerData: record
        })

    }
    salesPersonChange=(value)=>{
        this.setState({
            salesPerson: value
        })
    }
    supportAccManagerChange=(value)=>{
        this.setState({
            supportAccManager: value
        })
    }

    render() {
        let siteData = this.props.location.state.siteData
    //   console.log("siteData:::",siteData)
    //   console.log("siteData:::",siteData.groupId)
       
         let location =  siteData.footerAddress && siteData.footerAddress.location ? siteData.footerAddress.location : undefined
        let displayPLP = ''
        this.state.displayPLP ? displayPLP = "block" : displayPLP = "none"
        let photo = ''
        this.state.photo ? photo = "block" : photo = "none"
        const { getFieldDecorator, getFieldValue } = this.props.form;

        let bb=  this.state.currentB && this.state.currentB.balance ? this.state.currentB.balance/100 : "0.00"
        let balane = bb.toLocaleString(`en-${
            !(this.props.location.state.siteData.curr) && this.props.location.state.siteData.curr == 'INR' ? 'IN' : 
            this.props.location.state.siteData.curr == 'USD' ? 
            'US' : 'IN'}`, { style: 'currency', currency: this.props.location.state.siteData.curr ? this.props.location.state.siteData.curr : "INR" })

        const { selectedRowKeys } = this.state;
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">Upload</div>
            </div>
        );

      
        let date = new Date()
        let epo = Math.floor(date / 1000)
        // console.log("epo", epo)
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
        };
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
        };
        let chargesEnum = [{
            name: "PAY PER USE",
            typeValue: "PAY_PER_USE",
        }, {
            name: "MONTHLY",
            typeValue: "MONTHLY",
        }, {
            name: "YEARLY",
            typeValue: "YEARLY",
        }]
        // let chargeData = this.state.serviceData != undefined ? this.state.serviceData.siteServices : []
        // // console.log("chargeData",this.state.specTypeDisplay)
        // // id= chargeData.length
        // getFieldDecorator('keys', { initialValue: chargeData });
        // const keys = getFieldValue('keys');
        // const formItems = keys.map((k, index) => (
        //     <Row key={k} gutter={24}>
        //         <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }}>
        //             <Form.Item
        //                 className="customF"
        //                 {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
        //                 label={index === 0 ? <span style={{ fontSize: '15px', fontWeight: 500, }}>Service</span> : ''}
        //                 required={false}
        //                 key={index + "service"}
        //             >
        //                 {getFieldDecorator(`charges[${index}]serviceName`, {
        //                     validateTrigger: ['onChange', 'onBlur'],
        //                     initialValue: k.serviceName,
        //                     rules: [
        //                         {
        //                             required: true,
        //                             whitespace: true,
        //                             message: "Please input select Service or delete this field.",
        //                         },
        //                     ],
        //                 })(<Select disabled style={{ width: '100%' }} onChange={(e) => this.handleTypeChange(index, e)} placeholder="Select Service" >
        //                     {this.state.allService.map((val, i) => {
        //                         return (
        //                             <Option value={val.serviceName}>{val.serviceName}</Option>
        //                         )
        //                     })}
        //                 </Select>)}
        //             </Form.Item>
        //         </Col>
        //         <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }}>
        //             <FormItem
        //                 className="customF"
        //                 {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
        //                 label={index === 0 ? <span style={{ fontSize: '15px', fontWeight: 500 }}>Charge Model</span> : ''}
        //                 required={false}
        //                 key={index + "Charge Model"}
        //             >
        //                 {getFieldDecorator(`charges[${index}]chargingModel`, {
        //                     validateTrigger: ['onChange', 'onBlur'],
        //                     initialValue: k.chargingModel,
        //                     rules: [
        //                         {
        //                             required: true,
        //                             whitespace: true,
        //                             message: "Please input select Service or delete this field.",
        //                         },
        //                     ],
        //                 })(<Select style={{ width: '100%' }} onChange={(e) => this.handleTypeChange(index, e)} placeholder="Select Service" >
        //                     {chargesEnum.map((val, i) => {
        //                         return (
        //                             <Option value={val.typeValue}>{val.name}</Option>
        //                         )
        //                     })}
        //                 </Select>)}
        //             </FormItem>
        //         </Col>
        //         {this.state.specTypeDisplay && (this.state.specTypeDisplay[index] == 'MONTHLY' || this.state.specTypeDisplay[index] == 'YEARLY' || (this.state.specTypeDisplay[index] == 'PAY_PER_USE') || this.state.specStatus[index] == 'ACTIVE' || this.state.specStatus[index] == 'TRIAL' || this.state.specStatus[index] == 'FREE') &&
        //             <>
        //                 <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }}>
        //                     <FormItem
        //                         className="customF"
        //                         {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
        //                         label={index === 0 ? <span style={{ fontSize: '15px', fontWeight: 500 }}>Start Date</span> : ''}
        //                         required={false}
        //                         key={index + "Start Date"}
        //                     >
        //                         {getFieldDecorator(`charges[${index}]startDate`, {
        //                             // initialValue: k.charges ? k.charges/100 : null,
        //                             rules: [
        //                                 {
        //                                     required: true,
        //                                     message: "Please input select Service or delete this field.",
        //                                 },
        //                             ],
        //                         })(<DatePicker></DatePicker>)}
        //                     </FormItem>
        //                 </Col>
        //             </>
        //         }
        //         {this.state.specTypeDisplay && (this.state.specTypeDisplay[index] == 'MONTHLY' || this.state.specTypeDisplay[index] == 'YEARLY' || this.state.specStatus[index] == 'ACTIVE' || this.state.specStatus[index] == 'TRIAL' || this.state.specStatus[index] == 'FREE') &&
        //             <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }}>
        //                 <FormItem
        //                     className="customF"
        //                     {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
        //                     label={index === 0 ? <span style={{ fontSize: '15px', fontWeight: 500 }}>End Date</span> : ''}
        //                     required={false}
        //                     key={index + "End Date"}
        //                 >
        //                     {getFieldDecorator(`charges[${index}]startEnd`, {
        //                         // initialValue: k.charges ? k.charges/100 : null,
        //                         rules: [
        //                             {
        //                                 required: this.state.wallet ? true : false,
        //                                 message: "Please input select Service or delete this field.",
        //                             },
        //                         ],
        //                     })(<DatePicker></DatePicker>)}
        //                 </FormItem>
        //             </Col>
        //         }
        //         {k.startDate && this.state.specTypeDisplay.length == 0  && (
        //             <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }}>
        //                 <FormItem
        //                     className="customF"
        //                     {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
        //                     label={index === 0 ? <span style={{ fontSize: '15px', fontWeight: 500 }}>Start Date</span> : ''}
        //                     required={false}
        //                     key={index + "Start Date"}
        //                 >
        //                     {getFieldDecorator(`charges[${index}]startDate`, {
        //                         initialValue: k.startDate ? moment(k.startDate) : null,
        //                         rules: [
        //                             {
        //                                 required: true,
        //                                 message: "Please input select Service or delete this field.",
        //                             },
        //                         ],
        //                     })(<DatePicker></DatePicker>)}
        //                 </FormItem>
        //             </Col>
        //         )

        //         }
        //         {k.endDate && this.state.specTypeDisplay.length == 0  && (
        //             <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }}>
        //                 <FormItem
        //                     className="customF"
        //                     {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
        //                     label={index === 0 ? <span style={{ fontSize: '15px', fontWeight: 500 }}>End Date</span> : ''}
        //                     required={false}
        //                     key={index + "End"}
        //                 >
        //                     {getFieldDecorator(`charges[${index}]startEnd`, {
        //                         initialValue: k.endDate ? moment(k.endDate) : null,
        //                         rules: [
        //                             {
        //                                 required: true,
        //                                 message: "Please input select Service or delete this field.",
        //                             },
        //                         ],
        //                     })(<DatePicker ></DatePicker>)}
        //                 </FormItem>
        //             </Col>
        //         )
        //         }
        //         <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }}>
        //             <FormItem
        //                 className="customF"
        //                 {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
        //                 label={index === 0 ? <span style={{ fontSize: '15px', fontWeight: 500 }}>Rate</span> : ''}
        //                 required={false}
        //                 key={index + "Rate"}
        //             >
        //                 {getFieldDecorator(`charges[${index}]charges`, {
        //                     validateTrigger: ['onChange', 'onBlur'],
        //                     initialValue: k.charges ? k.charges / 100 : null,
        //                     rules: [
        //                         {
        //                             required: true,
        //                             message: "Please input select Service or delete this field.",
        //                         },
        //                     ],
        //                 })(<Input placeholder="Rate" type="Number" ></Input>)}
        //             </FormItem>
        //         </Col>
        //         <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }}>
        //             <FormItem
        //                 className="customF"
        //                 {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
        //                 label={index === 0 ? <span style={{ fontSize: '15px', fontWeight: 500 }}>Billing Status</span> : ''}
        //                 required={false}
        //                 key={index + "Billing Status"}
        //             >
        //                 {getFieldDecorator(`charges[${index}]status`, {
        //                     initialValue: k.status ? k.status : true,
        //                     rules: [
        //                         {
        //                             required: false,
        //                             message: "Please input select  status or delete this field.",
        //                         },
        //                     ],
        //                 })(<Select style={{ width: '90px' }} onChange={(e) => this.handleTypeChange(index, e)}>
        //                     <Option value={"ACTIVE"}>Active</Option>
        //                     {/* <Option value={"INACTIVE"}>Inactive</Option> */}
        //                     <Option value={"TRIAL"}>Trial</Option>
        //                     <Option value={"FREE"}>Free</Option>
        //                 </Select>)}
        //             </FormItem>
        //         </Col>
        //         <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }}>
        //             <FormItem
        //                 className="customF"
        //                 {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
        //                 label={index === 0 ? <span style={{ fontSize: '15px', fontWeight: 500 }}>Master Status</span> : ''}
        //                 required={false}
        //                 key={index + "Master Status"}
        //             >
        //                 {getFieldDecorator(`charges[${index}]masterStatus`, {
        //                     initialValue: k.masterStatus,
        //                     rules: [
        //                         {
        //                             required: true,
        //                             message: "Please input select  status or delete this field.",
        //                         },
        //                     ],
        //                 })(<Select style={{ width: '90px' }} >
        //                     <Option value={true}>On</Option >
        //                     <Option value={false}>Off</Option >
        //                 </Select>)}
        //             </FormItem>
        //         </Col>
        //         {/* <FormItem
        //             className="customF"
        //             {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
        //             label={index === 0 ? <span style={{ fontSize: '15px', fontWeight: 500 }}>Action</span> : ''}
        //             required={false}
        //         >
        //             <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 1 }} lg={{ span: 1 }}>
        //                 {keys.length > 1 ? (
        //                     <Icon
        //                         // className="dynamic-delete-button"
        //                         // className="customF"
        //                         type="minus-circle-o"
        //                         // style={{position: 'absolute',top: 0,bottom: 0}}
        //                         onClick={() => this.remove(k)}
        //                     />
        //                 ) : null}
        //             </Col>
        //         </FormItem> */}
        //         <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 1 }} lg={{ span: 1 }} key={index + "Master"}>
        //             {getFieldDecorator(`charges[${index}]startedAt`, {
        //                 initialValue: k.startedAt ? k.startedAt : epo,
        //                 rules: [
        //                     {
        //                         required: false,
        //                         message: "Please input select  status or delete this field.",
        //                     },
        //                 ],
        //             })}

        //             {getFieldDecorator(`charges[${index}]updatedAt`, {
        //                 initialValue: epo,
        //                 rules: [
        //                     {
        //                         required: false,
        //                         message: "Please input select  status or delete this field.",
        //                     },
        //                 ],
        //             })}
        //         </Col>
        //     </Row>

        // ));

        const columns = [
            {
                title: 'Page Name',
                dataIndex: 'displayName',
                key: 'pageName',
                width: '20%',
                // ...this.getColumnSearchProps('pageName'),
            },
            {
                title: 'Page Tag',
                // dataIndex: 'tags',
                key: 'tags',
                width: '15%',
                filters: this.state.tags,
                filterSearch: true,
                render: (data) => {
                    // console.log("data..",data)
                    return (
                        <div>
                            {data && data.tags && data.tags.length > 0 ? data.tags.map((val) => {
                                return (
                                    <Tag color="green" key={val}>
                                        {val}
                                    </Tag>
                                )
                            }) :
                                <span>--</span>
                            }
                        </div>
                    )
                },
                onFilter: (value, record) => {
                    if (record.tags != null) {
                        return record.tags.find(x => x == value)
                    }
                },
            },
            {
                title: 'Display',
                //   dataIndex: 'pageFor',
                key: 'pageFor',
                width: '10%',
                render: (data) => {
                    let check = data.pageFor == null ? "BOTH" : data.pageFor
                    return (
                        <span>{check == "MOB" ? "Mobile View" : check == "WEB" ? "Web View" : check}</span>
                    )
                }
            },
            {
                title: 'Page Type',
                //   dataIndex: 'pageType',
                key: 'pageType',
                width: '15%',
                render: (data) => {
                    return (
                        <span>{data.pageType == null ? "FROALA" : data.pageType}</span>
                    )
                }
            },
            {
                title: 'Preview Image',
                //   dataIndex: 'pageType',
                key: 'previewImage',
                width: '40%',
                align: 'center',
                render: (record) => {
                    return record.image != null ? (
                        <img
                            src={process.env.REACT_APP_S3_URL + '' + record.image.key}
                            style={{ width: '100%' }}
                            onClick={() => { this.setState({ fullImg: true, fullImage: process.env.REACT_APP_S3_URL + '' + record.image.key }) }}
                        />
                    ) : (
                        '--'
                    );
                }
            },


        ]
        const paymentModeColumns = [
            {
              title: "Payment Mode",
              dataIndex: "internalName",
              key: "internalName",
              width: "14%",
              render: (internalName) => {
                return(<span style={{color: "black"}}>{internalName}</span>)
              }
            },
            {
                title: "Display Name",
                dataIndex: "displayName",
                key: "displayName",
                width: "14%",
                // render: (internalName) => {
                //   return(<span style={{color: "black"}}>{internalName}</span>)
                // }
              },
            {
              title: "Action",
              key: "action",
              align: "center",
              width: "10%",
              render: (record) => {
                // console.log("record",this.state.selectedRecord)
                return (
                    <div>
                        <Tooltip placement="top" title="Delete Payment Mode">
                          <Popconfirm
                            title="Sure to delete?"
                            onConfirm={() => this.handleDeletePaymentModes(record)}
                          >
                            <Button
                              type="danger"
                              style={{
                                marginLeft: "5px",
                                cursor: "pointer",
                                background: "#ff0000 ",
                                color: "#fff",
                              }}
                            >Delete
                            </Button>
                          </Popconfirm>
                        </Tooltip>
                    </div>
                );
              },
            },
          ];
        const { previewImage, previewVisible, siteLogo } = this.state
        const validateFloatNumber = (rule, value, callback) => {
            // Regular expression to check if the value is a float number (positive or negative)
            const floatRegex = /^-?\d+(\.\d+)?$/;
        
            if (!value || floatRegex.test(value)) {
              callback(); // Validation passed
            } else {
              callback('Please enter a valid float number.'); // Validation failed
            }
          };
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onChangeHandle,
            // getCheckboxProps: (record) => {
            //     const rowIndex = state11.state.siteOptions1 == "BOOKING_RESTAURANTS"
            //     return {
            //       disabled: rowIndex ? record.pageName == "Booking Home Page - Mob" || record.pageName == "Booking Home Page - Web - Restaurants" || record.pageName == "Footer" || record.pageName == "Header":null,
            //     };
            //   },

        };
        return (   
            <App header={'Edit Site'}>
                {this.state.invoiceModal &&
                <Modal 
                    visible={this.state.invoiceModal} 
                    footer={null} 
                    onCancel={this.handleInvoice}
                    width={1300}
                >
                    <Invoice record={this.state.invoiceRecord} siteData={siteData}/>
                </Modal>}
                <Form onSubmit={this.handleSubmit} >
                    <div>
                        <Button type="primary" htmlType="submit" loading={this.state.loading} style={{ float: "right", marginBottom: 16, cursor: "pointer", color: "#fff" }}>
                            Save
                        </Button>
                        {/* {this.state.wallet &&
                        <Button type="primary" onClick={this.showModal} style={{ float: "left", marginBottom: 16, cursor: "pointer", color: "#fff" }}>
                            Payment Details
                        </Button>
                        } */}
                        <Divider style={{ fontSize: "20px" }}>Basic</Divider>
                        <Row gutter={64}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Customer Name' >
                                    {getFieldDecorator('custName', {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        initialValue: siteData.custName,
                                        rules: [{
                                            required: true,
                                            message: "Please enter customer name.",
                                        }],
                                    })(
                                        <Input placeholder="Customer Name" style={{ width: '100%' }} />
                                    )}
                                </FormItem>
                            </Col>

                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Email' >
                                    {getFieldDecorator('ownerEmail', {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        initialValue: siteData.contactEmail,
                                        rules: [{
                                            required: true,
                                            message: "Please enter email.",
                                        }],
                                    })(
                                        <Input placeholder="Email" style={{ width: '100%' }} />
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Mobile' >
                                    {getFieldDecorator('ownerMob', {
                                        initialValue: siteData.contactNumber,
                                        rules: [{
                                            required: true,
                                            message: "Please enter mobile number.",
                                        },
                                    //     {
                                    //         validator:(rule, value, cb)=>{
                                    //         if(value!=undefined && value!=''){
                                    //          let reggst = /^([+]\d{2})?\d{10}$/;
                                    //          if(!reggst.test(value)){
                                    //                 cb('Please Enter Valid Mobile Number')
                                    //            }
                                    //         }
                                    //         cb()
                                    //         }
                                    // }
                                ],
                                    })(
                                        <Input placeholder="Mobile Number" style={{ width: '100%' }} />
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <FormItem label="Additional Contact Numbers"  >
                                    {getFieldDecorator(`additionalContactNumbers`, {
                                           initialValue:  siteData.additionalContactNumbers,
                                        rules: [{
                                            required: false,
                                            message: "Please Enter Additional Contact Numbers",
                                        },
                                        {
                                            validator: (rule, value, cb) => {
                                                if (value) {
                                                //    for(let i=0;i<value.length;i++){
                                                //     if(value[i].length != 10){
                                                //      cb(`Please Enter valid Number`)
                                                //     }
                                                // }
                                                   
                                                }
                                                cb()
                                            }
                                        },
                                    ]
                                    })(
                                        <Select mode="tags" style={{ width: '100%', marginRight: 8 }} onInputKeyDown={this.handleKeyDown} placeholder="Enter Number"  >
                                            {children}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        {/* </Row>

                        <Row gutter={64}> */}
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Company Name' >
                                    {getFieldDecorator('companyName', {
                                        initialValue: siteData.companyName,
                                        rules: [{
                                            required: true,
                                            message: "Please enter company name.",
                                        }],
                                    })(
                                        <Input placeholder="Company Name" style={{ width: '100%' }} />
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='GST Number' >
                                    {getFieldDecorator('gstNo', {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        initialValue: siteData.gstNo,
                                        // rules: [{
                                        // required: true,
                                        // message: "Please enter GST number.",
                                        // }],
                                    })(
                                        <Input placeholder="Gst No" style={{ width: '100%' }} />
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Currency' >
                                    {getFieldDecorator('currency', {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        initialValue: "INR",
                                        rules: [{
                                            required: true,
                                            message: "Please enter currency.",
                                        }],
                                    })(
                                        <Select
                                            optionFilterProp="children"
                                            disabled
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            placeholder="Please select">
                                            {currency.map((c) => {
                                                return (
                                                    <Select.Option key={c.value} value={c.value} >{c.label}</Select.Option>
                                                )
                                            })}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={64}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Sub Domain' >
                                    {getFieldDecorator('subdomain', {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        initialValue: siteData.subdomain,
                                        rules: [{
                                            required: true,
                                            message: "Please enter sub domain.",
                                        }],
                                    })(
                                        <Input placeholder="Sub Domain" disabled style={{ width: '100%' }} />
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Domain' >
                                    {getFieldDecorator('domain', {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        initialValue: siteData.domain,
                                        rules: [{
                                            required: true,
                                            message: "Please enter domain.",
                                        }],
                                    })(
                                        <Input placeholder="Domain" disabled style={{ width: '100%' }} />
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Site Type'>
                                    {getFieldDecorator('siteType', {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        initialValue: siteData.siteType,
                                        rules: [{
                                            required: this.state.displayPLP,
                                            message: "Please enter site type.",
                                        }],
                                    })(
                                        <Select
                                            optionFilterProp="children"
                                            showSearch
                                            allowClear
                                            disabled
                                            onChange={this.handleSelectChange}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            placeholder="Please select">
                                            {type.map((c) => {
                                                return (
                                                    <Select.Option key={c.value} value={c.value} >{c.label}</Select.Option>
                                                )
                                            })}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={64}>
                            {this.state.displayPLP &&
                                <div>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>

                                        <FormItem label='Site Option' style={{ display: this.state.displayPLP || siteData.siteOptions != null ? displayPLP = "block" : displayPLP = "none" }}>
                                            {getFieldDecorator('siteOption', {
                                                initialValue: siteData.siteOptions,
                                                rules: [{
                                                    required: true,
                                                    message: "Please enter site option.",
                                                }],
                                            })(
                                                <Select
                                                    optionFilterProp="children"
                                                    disabled
                                                    onChange={this.handleSiteOption}
                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                    placeholder="Please select">
                                                    {siteOptions.map((c) => {
                                                        return (
                                                            <Select.Option key={c.value} value={c.value} >{c.label}</Select.Option>
                                                        )
                                                    })}
                                                </Select>
                                            )}
                                        </FormItem>
                                    </Col>
                                    {(siteData.siteOptions == 'COMMERCE' || siteData.siteOptions == "PRODUCT_DISPLAY") && <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>

                                        <FormItem label='Number of Products Allowed' style={{ display: displayPLP }}>
                                            {getFieldDecorator('no_of_products', {
                                                initialValue: siteData.no_of_products,
                                                validateTrigger: ['onChange', 'onBlur'],
                                                rules: [{
                                                    required: this.state.displayPLP,
                                                    message: "Please enter number.",
                                                }],
                                            })(
                                                <Input placeholder="No. Of Product Allowed" style={{ width: '100%' }} />
                                            )}
                                        </FormItem>
                                    </Col>}
                                </div>
                            }
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Number of Photos Allowed' style={{ display: photo }}>
                                    {getFieldDecorator('no_of_photos', {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        initialValue: siteData.no_of_photos,
                                        rules: [{
                                            required: this.state.photo,
                                            message: "Please enter No. of photo.",
                                        }],
                                    })(
                                        <Input placeholder="No. Of Photo Allowed" style={{ width: '100%' }} />
                                    )}
                                </FormItem>
                            </Col>
                       
                            {siteData.siteOptions == 'COMMERCE' && <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Shipping Option' style={{ display: displayPLP }}>
                                    <RadioGroup onChange={this.courierOption} value={this.state.courierOption}>
                                        <Radio value='SELF'>Self</Radio>
                                        <Radio value="SHIPROCKET">ShipRocket</Radio>
                                    </RadioGroup>
                                </FormItem>
                            </Col>}
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Site Mode' >
                                    <RadioGroup onChange={this.siteMode} value={this.state.siteMode}>
                                        <Radio value='UNDER_CONSTRUCTION' >Under Construction</Radio>
                                        <Radio value="LIVE">Live</Radio>
                                    </RadioGroup>
                                </FormItem>
                            </Col>

                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Sales Person' >
                                    {getFieldDecorator('salesPerson', {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        initialValue: siteData.salesPerson,
                                        rules: [{
                                            required: false,
                                            message: "Please enter sales person name.",
                                        }],
                                    })(
                                        <Select
                                            optionFilterProp="children"
                                            onChange={this.salesPersonChange}
                                            showSearch
                                            allowClear
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            placeholder="Please select Sales Person">
                                                {this.state.userData && this.state.userData.length > 0 &&
                                                this.state.userData.map((ele)=>{
                                                  
                                                    if(ele.userRole == 'SALES_PERSON'){
                                                    return(
                                                        <Select.Option key={ele.name} value={ele.name} >{ele.name}</Select.Option>
                                                    )
                                                }
                                                })}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>

                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Support Account Manager' >
                                    {getFieldDecorator('supportAccManager', {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        initialValue: siteData.supportAccManager,
                                        rules: [{
                                            required: false,
                                            message: "Please enter support Account Manager name.",
                                        }],
                                    })(
                                    <Select
                                        optionFilterProp="children"
                                        onChange={this.supportAccManagerChange}
                                        showSearch
                                        allowClear
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        placeholder="Please select Sales Person">
                                            {this.state.userData && this.state.userData.length > 0 &&
                                            this.state.userData.map((ele)=>{
                                                if(ele.userRole == 'SUPPORT_ACCOUNT_MANAGER'|| ele.userRole =='CUSTOMER_SUPPORT_LEAD'){
                                                return(
                                                    <Select.Option key={ele.name} value={ele.name} >{ele.name}</Select.Option>
                                                )
                                            }
                                            })}
                                    </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Partner Type' >
                                    {getFieldDecorator('partnerType', {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        initialValue: siteData.partnerType,
                                        rules: [{
                                            required: false,
                                            message: "Please enter Partner Type.",
                                        }],
                                    })(
                                        <Select
                                            optionFilterProp="children"
                                            showSearch
                                            allowClear
                                            onChange={this.partnerTypeChange}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            placeholder="Please select Customer Language">
                                                    <Select.Option key={'cp'} value={'CP'} >CP</Select.Option>
                                                    <Select.Option key={'reseller'} value={'RESELLER'} >Reseller</Select.Option>
                                                    <Select.Option key={'referral'} value={'REFERRAL'} >Referral</Select.Option>
                                                    <Select.Option key={'stockist'} value={'STOCKIST'} >Stockist</Select.Option>
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            {this.state.partnerType && this.state.partnerType == 'CP' &&
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Partner Name' >
                                    {getFieldDecorator('channelPartner', {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        initialValue: siteData.channelPartner,
                                        rules: [{
                                            required: false,
                                            message: "Please enter Partner Name.",
                                        }],
                                    })(

                                        <Select
                                        optionFilterProp="children"
                                        showSearch
                                        allowClear
                                        onChange={this.channelPartnerChange}
                                        // filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        placeholder="Please select Partner Name">
                                            {this.state.userData && this.state.userData.length > 0 &&
                                            this.state.userData.map((ele)=>{
                                                if(ele.userRole == 'CP' && ele.companyName ){
                                                   
                                                return(
                                                    <Select.Option key={ele.companyName} value={ele.companyName} >{ele.companyName}</Select.Option>
                                                )
                                            }
                                            })}
                                    </Select>
                                    )}
                                </FormItem>
                            </Col>
                        }
                        {  this.state.partnerType && this.state.partnerType == 'CP' && this.state.channelPartnerData && this.state.channelPartnerData.supportNumber != null &&
                         <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <FormItem label='Customer support Number'>
                            {getFieldDecorator('showSupportNumber', {
                              valuePropName: 'checked',
                              initialValue: siteData.supportNumber && siteData.supportNumber ? true : false,
                            })(
                              <Checkbox disabled={siteData.supportNumber && siteData.supportNumber ? true : false}>
                                Show Customer Support Number
                              </Checkbox>
                            )}
                          </FormItem>
                          </Col>
                        }
                        {this.state.partnerType && this.state.partnerType == 'STOCKIST' &&
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Stockist Name' >
                                    {getFieldDecorator('stockistName', {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        initialValue: siteData.stockistName,
                                        rules: [{
                                            required: false,
                                            message: "Please enter Stockist Name.",
                                        }],
                                    })(

                                        <Input placeholder="Stockist Name" style={{ width: '100%' }} />
                                    )}
                                </FormItem>
                            </Col>
                        }
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='User Name' >
                                    {getFieldDecorator('userName', {
                                         initialValue: siteData.userName ? siteData.userName : '',
                                        rules: [{
                                            required: false,
                                            message: "Please enter User Name.",
                                        }],
                                    })(

                                        <Input placeholder="User Name" disabled style={{ width: '100%' }} />
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Latitude' className="booking-label">
                                    {getFieldDecorator('latitude', {
                                         initialValue: location &&  location.lat ? location.lat :'',
                                        rules: [{
                                            required: false,
                                            message: "Please enter ",
                                        },{
                                            validator:validateFloatNumber
                                        }],
                                    })(<Input placeholder='Latitude'/>)}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Longitude' className="booking-label">
                                    {getFieldDecorator('longitude', {
                                         initialValue: location &&  location.long ? location.long :'',
                                        rules: [{
                                            required: false,
                                            message: "Please enter ",
                                        },{
                                            validator:validateFloatNumber
                                        }],
                                    })(<Input placeholder='Longitude' />
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Is Demo site' className="booking-label">
                                    {getFieldDecorator('isDemoSite', {
                                        initialValue: siteData.isDemoSite ? siteData.isDemoSite : false,
                                        rules: [{
                                            required: false,
                                            message: "Please enter ",
                                        }],
                                    })(
                                    <RadioGroup>
                                        <Radio value={true} >Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </RadioGroup>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                            <>
                                <Divider style={{ fontSize: "20px" }} >Online Payment</Divider>
                                <Row>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                        <FormItem label='Payment Option' style={{ display: displayPLP }}>
                                            <CheckboxGroup options={payOptions} defaultValue={siteData.integratePaymentOption} onChange={this.onChangePayment} />
                                        </FormItem>
                                    </Col>
                                </Row>
                            </>

                        <>
                            <Divider style={{ fontSize: "20px" }}>Wallet Settings</Divider>
                            <Row gutter={64}>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                                    <>
                                        <FormItem label='Wallet Enabled' >
                                            <RadioGroup onChange={this.walletchange} value={this.state.wallet}>
                                                <Radio value={true} >Yes</Radio>
                                                <Radio value={false}>No</Radio>
                                            </RadioGroup>
                                        </FormItem>
                                    </>
                                </Col>
                            </Row>
                        </>

                        <>
                                <Divider style={{ fontSize: "20px" }} >Payment Modes</Divider>
                                    <Row>
                                            <Row gutter={24}>
                                                <Col xs={{span:10}} sm={{span:10}} md={{span:8}} lg={{span:8}} xl={{span:8}}>
                                                <FormItem className="booking-label" label="Payment Mode" >
                                            {getFieldDecorator(`internalName`, {
                                                    rules: [{
                                                    required: true,
                                                    message: "Please Select Payment Mode",
                                                        }]
                                            })(
                                                <Select getPopupContainer={trigger => trigger.parentNode} placeholder="Select Payment Mode" key="paymentModes" onChange={(value) => this.setState({internalName: value.split("::")[0], paymentState: value.split("::")[1]})}>
                                                     {this.state.paymentModesAvailable.map(paymentMode => <Select.Option key={paymentMode.internalName} value={`${paymentMode.internalName}::${paymentMode.paymentState}`} disabled={paymentMode.disabled}>{paymentMode.internalName}</Select.Option> )}
                                                </Select>    
                                            )}</FormItem>
                                                </Col>
                                                <Col xs={{span:10}} sm={{span:10}} md={{span:8}} lg={{span:8}} xl={{span:8}}>
                                                <FormItem className="booking-label" label="Display Name">
                                            {getFieldDecorator(`displayName`, {
                                                    rules: [{
                                                    required: true,
                                                    message: "Please Enter Unit",
                                                        }]
                                            })(
                                                <Input placeholder="Enter Display Name For the Selected Payment Mode" onChange={(event) => this.setState({displayName: event.target.value})} />)}</FormItem>
                                                </Col>
                                                <Col span={4}>
                                                    <Button
                                                    type="default"
                                                    onClick={() => this.handleAddPaymentModes(this.state.internalName, this.state.paymentState, this.state.displayName)}
                                                    style={{
                                                        cursor: "pointer",  background: "#389e0d", color: "#fff", padding: "0 2rem", marginTop: "44px"
                                                    }}
                                                    >ADD
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Table dataSource={this.state.paymentModes?this.state.paymentModes:[]} columns={paymentModeColumns} />
                                            </Row>
                                    </Row>
                            </>


                        <Divider style={{ fontSize: "20px" }}>Settings</Divider>
                        <Row gutter={64}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label="Site Logo (Upload image with (600 px * 200 px) dimensions)" className="booking-label">
                                    {getFieldDecorator('siteLogo', {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        rules: [{
                                            required: false,
                                            message: " Images are required"
                                        }]
                                    })(
                                        <Upload
                                            listType="picture-card"
                                            defaultFileList={Object.keys(this.state.iconImg).length != 0 ? [this.state.iconImg] : null}
                                            data={this.state.iconImg}
                                            onPreview={this.handlePreview}
                                            onChange={this.handleMainImage}
                                            beforeUpload={this.beforeUpload}
                                            onRemove={this.handleMainImageRemove}
                                        >
                                            {Object.keys(this.state.iconImg).length != 0 ? null : uploadButton}
                                        </Upload>
                                    )}

                                    <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                        <div>
                                            <img id="sitelogo" key="sitelogo" style={{ width: '100%' }} src={previewImage} />
                                        </div>
                                    </Modal>
                                </FormItem>

                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Home Page Title (Used for SEO)' className="booking-label">
                                    {getFieldDecorator('homeTitle', {
                                        initialValue: siteData && siteData.homePageTitle ? siteData.homePageTitle : '',
                                        rules: [{
                                            required: false,
                                            message: "Please enter home page title.",
                                        }],
                                    })(

                                        <Input placeholder="Home Page Title" style={{ width: '100%' }} />
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Home Page Meta Description(256 Characters - Used for SEO)'
                                    className="booking-label" >
                                    {getFieldDecorator('metaDescription', {
                                        initialValue: siteData && siteData.homePageMetadesc ? siteData.homePageMetadesc : '',
                                        rules: [{
                                            max: 256,
                                        }],
                                    })(

                                        <TextArea rows={4} placeholder="Home Page Meta Description" style={{ width: '100%' }} />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={64}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>

                                <FormItem label='Address Line 1' className="booking-label">
                                    {getFieldDecorator('addressLineOne', {
                                        initialValue: siteData &&
                                            siteData.footerAddress != null && siteData.footerAddress.addressLineOne != null ?
                                            siteData.footerAddress.addressLineOne : '',

                                        rules: [{
                                            required: false,
                                            message: "Please enter Address Line 1",
                                        }],
                                    })(

                                        <TextArea rows={2} placeholder="Address Line 1" />
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Address Line 2' className="booking-label">
                                    {getFieldDecorator('addressLineTwo', {
                                        initialValue: siteData &&
                                            siteData.footerAddress != null && siteData.footerAddress.addressLineTwo != null ?
                                            siteData.footerAddress.addressLineTwo : '',
                                        rules: [{
                                            required: false,
                                            message: "Please enter Address Line 2",
                                        }],
                                    })(

                                        <TextArea rows={2} placeholder="Address Line 2" />
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='City' className="booking-label">
                                    {getFieldDecorator('city', {
                                        initialValue: siteData &&
                                            siteData.footerAddress != null && siteData.footerAddress.city != null ?
                                            siteData.footerAddress.city : '',
                                        rules: [{
                                            required: false,
                                            message: "Please enter city.",
                                        }],
                                    })(

                                        <Input placeholder="City" />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={64}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='State' className="booking-label" >
                                    {getFieldDecorator('state', {
                                        initialValue: siteData &&
                                            siteData.footerAddress != null && siteData.footerAddress.state != null ?
                                            siteData.footerAddress.state : '',
                                        rules: [{
                                            required: false,
                                            message: "Please enter state",
                                        }],
                                    })(

                                        <Input placeholder="State" />
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Zip' className="booking-label">
                                    {getFieldDecorator('zip', {
                                        initialValue: siteData &&
                                            siteData.footerAddress != null && siteData.footerAddress.zip != null ?
                                            siteData.footerAddress.zip : '',
                                        rules: [{
                                            required: false,
                                            message: "Please enter zip.",
                                        }],
                                    })(
                                        <Input placeholder="Zip" />
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Country' className="booking-label">
                                    {getFieldDecorator('country', {
                                        initialValue: siteData &&
                                            siteData.footerAddress != null && siteData.footerAddress.country != null ?
                                            siteData.footerAddress.country : '',
                                        rules: [{
                                            required: false,
                                            message: "Please enter country",
                                        }],
                                    })(

                                        <Select onChange={this.onCountryChange}>
                                            {country.map((c) => {
                                                return (
                                                    <Option key={c.value} value={c.value} >{c.label}</Option>
                                                )
                                            })}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        {/* <Divider style={{ fontSize: "20px" }}>Color and Font</Divider>
                        <Row gutter={64}>

                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 6 }}>
                                <FormItem label='Background Color' className="booking-label">
                                    <Button icon="edit" style={{ marginBottom: "5px" }} onClick={this.handleImgClick} />
                                    <div><div style={{ background: this.state.bg == "" ? "#FFFFFF" : this.state.bg, width: "20px", height: "20px", fontSize: "30px", border: '1px solid #ddd' }}></div></div>
                                    {this.state.showPicker &&
                                        <ReactColor
                                            showPicker={this.state.showPicker}
                                            onCancel={this.handleColorClose}
                                            onAccept={this.handleBgClose}
                                            onChangeComplete={this.handleChangeComplete}
                                            color={this.state.bg}
                                        />
                                    }
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Menu Color' >
                                    <Button icon="edit" style={{ marginBottom: "5px" }} onClick={this.handleMenuClick} />
                                    <div><div style={{ background: this.state.menuColor == "" ? "##ea2b1f" : this.state.menuColor, width: "20px", height: "20px", fontSize: "30px", border: '1px solid #ddd' }}></div></div>
                                    {this.state.showMenu &&
                                        <ReactColor
                                            showPicker={this.state.showMenu}
                                            onCancel={this.handleColorMenuClose}
                                            onAccept={this.handleMenuClose}
                                            onChangeComplete={this.handleMenuComplete}
                                            color={this.state.menuColor}
                                        />
                                    }
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Text Font Color' className="booking-label">
                                    <Button icon="edit" style={{ marginBottom: "5px" }} onClick={this.handleTextClick} />
                                    <div><div type="bg-colors" style={{ background: this.state.textColor == "" && this.state.textColor != null ? "#000000" : this.state.textColor, fontSize: "30px", width: "20px", height: "20px", border: '1px solid #ddd' }}></div></div>
                                    {this.state.showText &&
                                        <PhotoshopPicker
                                            color={this.state.textColor}
                                            onChangeComplete={this.handleChangeText}
                                            onAccept={this.handleTextClose}
                                            onCancel={this.textClose}
                                        />
                                    }
                                </FormItem>
                            </Col>
                        </Row> */}
                        {/* <Row gutter={64}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Select Font Style' className="booking-label">
                                    <Select
                                        value={this.state.fontStyle}
                                        showSearch
                                        placeholder="Search to Select Font Style"
                                        optionFilterProp="children"
                                        style={{ width: "100%", marginRight: 8 }}
                                        onChange={this.handleFontStyle}
                                    >
                                        {fontType.map(tvalue => {
                                            // console.log('value', tvalue)
                                            return (
                                                <Option key={tvalue.value}>{tvalue.value}</Option>
                                            );
                                        })}
                                    </Select>
                                </FormItem>
                            </Col>
                        </Row> */}

                        <Divider style={{ fontSize: "20px" }}>Premium Features</Divider>
                        <Row gutter={64}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Send Order Whatsapp to Seller ' >
                                    <RadioGroup onChange={this.whatsappToSeller} value={this.state.whatsappToSeller}>
                                        <Radio value={true} >Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </RadioGroup>
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Send Order Whatsapp to Buyer' >
                                    <RadioGroup onChange={this.whatsappToBuyer} value={this.state.whatsappToBuyer}>
                                        <Radio value={true} >Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </RadioGroup>
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Send Order Email to Buyer' >
                                    <RadioGroup onChange={this.emailToBuyer} value={this.state.emailToBuyer}>
                                        <Radio value={true} >Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </RadioGroup>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={64}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Send Whatsapp To Buyer After Order Completion' >
                                    <RadioGroup onChange={this.feedBackToBuyer} value={this.state.feedBackToBuyer}>
                                        <Radio value={true} >Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </RadioGroup>
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Enable Inventory' >
                                    <RadioGroup onChange={this.inventory} value={this.state.inventory}>
                                        <Radio value={true} >Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </RadioGroup>
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Enable Front Site' >
                                    <RadioGroup onChange={this.frontSite} value={this.state.frontSite}>
                                        <Radio value={true} >Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </RadioGroup>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={64}>
                            {this.props.location && this.props.location.state && this.props.location.state.mainSites && this.props.location.state.mainSites.length && (
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <FormItem label='Reference Site' >
                                        {getFieldDecorator('groupId', {
                                                initialValue: siteData && siteData.groupId != null ? siteData.groupId : undefined,
                                                rules: [{
                                                    required: false,
                                                    message: "Please enter User Name.",
                                                }],
                                            })(

                                                <Select
                                                showSearch
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                placeholder="Select Reference Site"
                                                disabled={siteData && siteData.groupId != null ? true : false}
                                                onChange={this.referenceSite}>
                                                <Select.Option key={"null"} value={null} >-</Select.Option>
                                                {this.state.siteOptionVal === "DEPARTMENT"
                                                    ? this.props.location.state.mainSites.map((c) => {
                                                        return (
                                                            <Select.Option key={c.siteId} value={c.siteId}>
                                                            {c.fqdn}
                                                            </Select.Option>
                                                        );
                                                    })
                                                    : this.props.location.state.masterData.map((c) => {
                                                        return (
                                                            <Select.Option key={c.siteId} value={c.siteId}>
                                                            {c.fqdn}
                                                            </Select.Option>
                                                        );
                                                })}
                                                </Select>
                                            )}
                                    </FormItem>
                                </Col>
                            )}
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Allow Whatsapp Numbers' >
                                    {getFieldDecorator('availableWhatsApp', {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        initialValue: siteData && siteData.availableWhatsApp != null ? siteData.availableWhatsApp : 1,
                                        rules: [{
                                            required: false,
                                            message: "Please enter User Name.",
                                        }],
                                    })(

                                        <Input type="number" style={{ width: '50%' }} />
                                    )}
                                </FormItem>
                            </Col>
                            {
                                this.state.groupId && <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Group Reward System' className="booking-label">
                                    {getFieldDecorator('isGroupReward', {
                                        initialValue: this.state.isGroupReward,
                                        rules: [{
                                            required: false,
                                            message: "Please enter country",
                                        }],
                                    })(
                                    <RadioGroup>
                                        <Radio value={true} >Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </RadioGroup>
                                    )}
                                </FormItem>
                            </Col>
                            }
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label={<span>
                                        Enable Custom Whatsapp 
                                        { this.state.isCustomWhatsapp && <Button
                                                                                onClick={this.showWhatsAppConfigFunction}
                                                                                type="edit"
                                                                                icon="edit"
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    background: "green",
                                                                                    color: "#fff",
                                                                                    marginLeft: "5px",
                                                                                }}
                                                                                /> }
                                    </span> 
                            }
                                                                            >
                                    <RadioGroup onChange={this.isCustomWhatsapp} value={this.state.isCustomWhatsapp}>
                                        <Radio value={true} >Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </RadioGroup>
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <FormItem label='Enable Day Closure' className="booking-label">
                                    {getFieldDecorator('isDayClosureEnabled', {
                                        initialValue: siteData && siteData.isDayClosureEnabled ? siteData.isDayClosureEnabled : false,
                                        rules: [{
                                            required: false,
                                            message: "Please enter country",
                                        }],
                                    })(
                                    <RadioGroup>
                                        <Radio value={true} >Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </RadioGroup>
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <FormItem label='Is Counter Enabled' className="booking-label">
                                    {getFieldDecorator('isCounterEnabled', {
                                        initialValue: siteData && siteData.isCounterEnabled ? siteData.isCounterEnabled : false,
                                        rules: [{
                                            required: false,
                                            message: "Please enter country",
                                        }],
                                    })(
                                    <RadioGroup>
                                        <Radio value={true} >Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </RadioGroup>
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <FormItem label='Is Manage Return Enabled' className="booking-label">
                                    {getFieldDecorator('isReturnOrderEnabled', {
                                        initialValue: siteData && siteData.isReturnOrderEnabled ? siteData.isReturnOrderEnabled : false,
                                        rules: [{
                                            required: false,
                                            message: "Please enter country",
                                        }],
                                    })(
                                    <RadioGroup>
                                        <Radio value={true} >Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </RadioGroup>
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}> 
                            <FormItem label='New Order App' className="booking-label">
                                    {getFieldDecorator('newOrderAppEnabled', {
                                        initialValue: siteData && siteData.newOrderAppEnabled ? siteData.newOrderAppEnabled : false,
                                        rules: [{
                                            required: false,
                                            message: "Please Select option",
                                        }],
                                    })(
                                    <RadioGroup>
                                        <Radio value={true} >Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </RadioGroup>
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}> 
                            <FormItem label='Manual Day Closure' className="booking-label">
                                    {getFieldDecorator('isManualDayClosure', {
                                        initialValue: siteData && siteData.isManualDayClosure ? siteData.isManualDayClosure : false,
                                        rules: [{
                                            required: false,
                                            message: "Please Select option",
                                        }],
                                    })(
                                    <RadioGroup>
                                        <Radio value={true} >Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </RadioGroup>
                                    )}
                                </FormItem>
                            </Col>
                            { siteData.groupId &&
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}> 
                            <FormItem label='Manage Customer on Group' className="booking-label">
                                    {getFieldDecorator('isCustOnGroupEnabled', {
                                        initialValue: siteData && siteData.isCustOnGroupEnabled ? siteData.isCustOnGroupEnabled : false,
                                        rules: [{
                                            required: false,
                                            message: "Please Select option",
                                        }],
                                    })(
                                    <RadioGroup>
                                        <Radio value={true} >Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </RadioGroup>
                                    )}
                                </FormItem>
                            </Col>
                            }
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}> 
                            <FormItem label='Dash Board Date Range' className="booking-label">
                                    {getFieldDecorator('dashboardRangeEnabled', {
                                        initialValue: siteData && siteData.dashboardRangeEnabled ? siteData.dashboardRangeEnabled : false,
                                        rules: [{
                                            required: false,
                                            message: "Please enter country",
                                        }],
                                    })(
                                    <RadioGroup>
                                        <Radio value={true} >Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </RadioGroup>
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}> 
                            <FormItem label='HTMLPrint' className="booking-label">
                                    {getFieldDecorator('htmlPrint', {
                                        initialValue: siteData && siteData.htmlPrint ? siteData.htmlPrint : false,
                                        rules: [{
                                            required: false,
                                            message: "Please enter country",
                                        }],
                                    })(
                                    <RadioGroup>
                                        <Radio value={true} >Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </RadioGroup>
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}> 
                                <FormItem label='Order Timeline Enabled' className="booking-label">
                                    {getFieldDecorator('isOrderTimelineEnabled', {
                                        initialValue: siteData.premiumFeature && siteData.premiumFeature.isOrderTimelineEnabled ? siteData.premiumFeature.isOrderTimelineEnabled : false,
                                    })(
                                    <RadioGroup>
                                        <Radio value={true} >Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </RadioGroup>
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}> 
                                <FormItem label='Master Kds Enabled' className="booking-label">
                                    {getFieldDecorator('isMasterKdsEnabled', {
                                        initialValue: siteData.premiumFeature && siteData.premiumFeature.isMasterKdsEnabled ? siteData.premiumFeature.isMasterKdsEnabled : false,
                                    })(
                                    <RadioGroup>
                                        <Radio value={true} >Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </RadioGroup>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Divider style={{ fontSize: "20px" }}>Static Pages</Divider>
                        <Input onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} onChange={(e) => { this.onchangeSearch(e.target.value) }} style={{ marginLeft: '10px', width: '25%', marginBottom: '5px', borderRadius: '5px' }} placeholder="Search for Page" />

                        {this.state.pages.length > 0 && <strong style={{ marginLeft: '25px', color: 'blue' }}>{this.state.pages.length} {this.state.pages.length == 1 ? "page " : "pages "}selected</strong>}
                        {this.state.data.length != 0 && <Table rowKey="id" columns={columns} scroll={{ y: '10%' }} rowSelection={rowSelection} dataSource={this.state.searched ? this.state.data11 : this.state.data}
                            pagination={{ defaultPageSize: 20 }}
                        />}

                        <Modal
                            centered
                            visible={this.state.fullImg}
                            onOk={() => { this.setState({ fullImg: false, fullImage: "", fullImg: false }) }}
                            onCancel={() => { this.setState({ fullImg: false, fullImage: "", fullImg: false }) }}
                            footer={null}
                            width={800}
                        >
                            <div>
                                {this.state.fullImage != '' ?

                                    <div style={{ height: '550px' }}>
                                        <PinchZoomPan doubleTapBehavior="zoom" position="center"
                                        // zoomButtons={false}
                                        >
                                            <img src={this.state.fullImage}
                                            //  style={{width:"100%", height:"auto"}} 
                                            />
                                        </PinchZoomPan>
                                    </div>
                                    :
                                    <h4>No Image Found !</h4>
                                }
                            </div>
                        </Modal>
                        <Modal
                            title={`Payment Details : ${this.props.location.state.siteData.fqdn}`}
                            visible={this.state.paymentModal}
                            onOk={() => { this.setState({ paymentModal: false }) }}
                            onCancel={() => { this.setState({ paymentModal: false }) }}
                            footer={null}
                            width={1300}
                            // bodyStyle={{height: 530}}
                        >
                            
                            <div style={{overflowY: 'scroll',height: 'auto', overflowX: 'hidden'}}>
                            {/* <p style={{ textAlign: 'left' }}>{this.props.location.state.siteData.fqdn}
                            </p> */}
                            <p style={{ textAlign: 'end' }}>
                                <Button type="dashed" style={{  marginRight: "20px", margintop: '10px', marginBottom: '20px' }} >
                                   <span>Balance: <b>{balane}</b></span>
                                </Button>
                                <Button type="primary" style={{ background: "#389e0d", color: "#fff", marginRight: "20px", margintop: '10px', marginBottom: '20px' }} onClick={()=>this.modalChange()} >
                                   Re-Charge
                                </Button>
                        </p>
                        <Tabs onChange={this.callback} type="card">
                            <TabPane tab="Recurring Services" key="1">
                            {this.state.wallet == true && (
                                <>
                                    {/* {formItems} */}
                                    {/* <FormItem {...formItemLayoutWithOutLabel}>
                                        <Button type="dashed" onClick={this.add} style={{ width: '60%' }}>
                                            <Icon type="plus" /> Add Service
                                        </Button>
                                    </FormItem> */}
                                </>
                            )}
                            <Button type="primary" onClick={(e)=>this.handleSubmit(e)} loading={this.state.loading} style={{ float: "left", marginBottom: 16, cursor: "pointer", color: "#fff" }}>
                            Save
                            </Button>
                            </TabPane>
                            <TabPane tab="One Time Services" key="2">
                            <AddExtraService siteData={this.props.location.state.siteData} getRecharge={this.getRecharge} />

                                </TabPane>
                            <TabPane tab="Credit Transactions" key="3">
                            <Table 
                                columns={this.columns} 
                                dataSource={this.state.transaction}
                                loading={this.state.loading}
                                />
                            </TabPane>
                            <TabPane tab="Manage Subscription" key="4">
                            <SpAddToSite siteResp={this.props.location.state.siteData} isEdit={true} ms={this.state.masterSer} recData={this.state.recData}/>
                            </TabPane>
                            </Tabs>
                            
                            </div>
                        </Modal>
                        

                    </div>
                </Form>
                <Modal
                    title="Wallet Recharge"
                    visible={this.state.visibleM}
               
                    onCancel={this.handleCancel}
                    footer={null}
                  >
                    <Form>
                    <FormItem
                                label="Recharge Amount"
                            >
                                {getFieldDecorator(`amount`, {
                                //    initialValue: this.state.settingData.companyName,
                                    rules: [{
                                        required: false,
                                        message: "Please enter Recharge Amount between 100 To 100000",
                                        // min:3,max:6
                                    },
                                    ,
                                    {
                                      validator: (rule, value, cb) => {
                                          if (value <1) {

                                              // if (value <100) {
                                                  cb('Please enter Recharge Amount Grater than 100')
                                              // }
                                          }
                                          cb()
                                      }
                                  }
                                   ]
                                })(
                                  <Input   type= "number" placeholder="Enter Recharge Amount" style={{width:"50%"}} onChange={this.rAmount}></Input>
                                )}
                            </FormItem>
                            <FormItem label='Notes' >
                                    {getFieldDecorator('note', {
                                       
                                        rules: [{
                                            required: true,
                                            message: "note",
                                        }],
                                    })(
                                        <Input placeholder="Note" style={{ width: '50%' }} />
                                    )}
                                </FormItem>
                    </Form>
                    <Row>
                      <Button type="primary" onClick={this.handleOk} style={{marginTop: "20px"}} loading={this.state.paymentLoading}>Submit</Button>
                    </Row>
                </Modal>
                <Modal
                    centered
                    visible={this.state.showWhatsAppConfig}
                    onCancel={() => this.handleWhatsAppConfigClose(false)}
                    footer={null}
                    width={500}
                >
                    <WhatsAppConfigModal handleWhatsAppConfigClose={this.handleWhatsAppConfigClose} client={this.props.client} domain={this.props.location.state.siteData.fqdn}/>
                </Modal>

            </App>
        )
    }
}
const editForm = Form.create()(EditSite);
const wrapEditSite = compose(
    withApollo,
    graphql(EditSitMutate, {
        name: 'editSitMutate'
    }),
    graphql(SitePages, {
        name: 'sitePages'
    }),
    graphql(updateSiteRewardSetting, {
        name: 'updateSiteRewardSetting'
    }),
    graphql(getSitePagesById, {
        options: props => ({
            fetchPolicy: "network-only"
        })
    })

)(editForm)
export default wrapEditSite