import gql from 'graphql-tag'

export default gql`
mutation createSiteByRecahoAdmin(
    $domain: String!
    $subdomain: String!
    $userName: String!
    $internalName: String!
    $enabled: Boolean!
    $siteType: SITE_TYPE!
    $siteOptions: SITE_OPTIONS
    $integratePaymentOption: [PAYMENT_MODE!]
    $currency: CURRENCY_CODE!
    $ownerEmail: String!
    $ownerMob: String!
    $additionalContactNumbers: [String]
    $no_of_products: Int
    $no_of_photos: Int
    $custName: String
    $companyName: String
    $gstNo: String
    $fromDate: Int
    $tillDate: Int
    $courierOption: COURIER_OPTION
    $homePageTitle: String
    $homePageMetadesc: String
    $siteLogo: S3ObjectInput
    $backgroundColor: String
    $menuFontColor: String
    $textFontColor: String
    $textFontType: String
    $footerAddress: GenericAddressInput
    $whatsAppSeller: Boolean
    $whatsAppBuyer: Boolean
    $emailAppBuyer: Boolean
    $feedBackToBuyer: Boolean
    $digitalCard: Boolean
    $SpeechSearch: Boolean
    $inventory: Boolean
    $frontSite: Boolean
    $availableWhatsApp: Int
    $invoice: Boolean
    $isDemoSite: Boolean
    $festivalBanners: Boolean
    $themeDesign: THEME_DESIGN
    $diningSetup: Boolean
    $isWalletEnabled: Boolean
    $isWebAppEnabled: Boolean
    $diningSettingMenu: String
    $siteMode: SITE_MODE
    $paymentModes: [PaymentModeConfigInput!]
    $isGroupAdmin: Boolean
    $groupId: String
    $customerLang: String
    $partnerType: String
    $isDayClosureEnabled: Boolean
    $salesPerson: String
    $supportAccManager: String
    $channelPartner: String
    $stockistName: String
    $isCounterEnabled: Boolean
    $isReturnOrderEnabled: Boolean
    $supportNumber: String
    $dashboardRangeEnabled: Boolean
    $premiumFeature: PremiumFeatureInput
) {
    createSiteByRecahoAdmin(input:{
      domain:$domain
      subdomain:$subdomain
      userName: $userName
      internalName:$internalName
      enabled:$enabled
      siteType:$siteType
      siteOptions:$siteOptions
      integratePaymentOption:$integratePaymentOption
      currency:$currency
      ownerEmail:$ownerEmail
      ownerMob:$ownerMob
      additionalContactNumbers: $additionalContactNumbers
      no_of_products:$no_of_products
      no_of_photos:$no_of_photos
      custName:$custName
      companyName: $companyName
      gstNo: $gstNo
      fromDate: $fromDate
      tillDate: $tillDate
      courierOption:$courierOption
      homePageTitle: $homePageTitle
      homePageMetadesc: $homePageMetadesc
      siteLogo: $siteLogo
      backgroundColor: $backgroundColor
      menuFontColor: $menuFontColor
      textFontColor: $textFontColor
      textFontType: $textFontType
      footerAddress: $footerAddress
      whatsAppSeller: $whatsAppSeller
      whatsAppBuyer: $whatsAppBuyer
      emailAppBuyer: $emailAppBuyer
      feedBackToBuyer: $feedBackToBuyer
      digitalCard: $digitalCard
      SpeechSearch: $SpeechSearch
      inventory: $inventory
      frontSite: $frontSite
      availableWhatsApp: $availableWhatsApp
      invoice:$invoice
      isDemoSite:$isDemoSite
      festivalBanners:$festivalBanners
      themeDesign: $themeDesign
      diningSetup: $diningSetup
      isWalletEnabled: $isWalletEnabled
      isWebAppEnabled: $isWebAppEnabled
      diningSettingMenu: $diningSettingMenu
      siteMode: $siteMode
      paymentModes: $paymentModes
      isGroupAdmin: $isGroupAdmin
      groupId: $groupId
      customerLang: $customerLang
      partnerType: $partnerType
      isDayClosureEnabled: $isDayClosureEnabled
      salesPerson: $salesPerson
      supportAccManager: $supportAccManager
      channelPartner: $channelPartner
      stockistName: $stockistName
      isCounterEnabled: $isCounterEnabled
      isReturnOrderEnabled: $isReturnOrderEnabled
      supportNumber: $supportNumber
      dashboardRangeEnabled: $dashboardRangeEnabled
      premiumFeature: $premiumFeature
    }){
      id
      domain
      siteId
      fqdn
      curr
      siteType
      siteOptions
      isCounterEnabled
      isReturnOrderEnabled
    }
}
`

