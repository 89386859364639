import React from "react";
import { Form, Input, Switch, Button, Col, Row, Select, message, Radio, Upload, Icon, Divider, Popconfirm } from "antd";
import { withApollo } from "react-apollo";
import slugify from "slugify";
import uuidv1 from "uuid/v1";
import Resizer from "react-image-file-resizer";
import createServiceType from "../../mutation/createServiceType";
import getServiceBySiteId from "../../queries/getServiceBySiteId";
import getServiceTypeSettingBySite from "../../queries/getServiceTypeSettingBySite";
import getInvoiceFormatV2 from "../../queries/getInvoiceFormatV2";
import updateServiceInBulkV2 from "../../mutation/updateServiceInBulkV2";
import updateServiceTypeSetting from "../../mutation/updateServiceTypeSetting";
import updateInvoiceFormatV2 from "../../mutation/updateInvoiceFormatV2";
import updateServiceType from "../../mutation/updateServiceType";
import storeRegisterONDC from "../../mutation/storeRegisterONDC";
import SiteActionEvent from "../../funComponent/SiteActionEvent";

import updateFalconStore from "../../mutation/updateFalconStore";
import convertFileToBase64 from "../../utils/convertFileToBase64"
import uploadbase64Files from "../../utils/uploadBase64Files"

import "../../App.css";
import getBasicSiteSetting from "../../queries/getBasicSiteSetting";
const { TextArea } = Input;
const { Option } = Select;

const FormItem = Form.Item;
const modalUiTexts = {
    MASTER_SERVICE_UPDATE: {
      modalTitle: "Update Menu",
      titleStart: "Menu",
    },
    ADD_SUB_MENU: {
      modalTitle: "Add Sub Menu",
      titleStart: "Sub Menu",
    },
    UPDATE_SUB_MENU: {
      modalTitle: "update Sub Menu",
      titleStart: "Sub Menu",
    },
  },
  reservationType = ["WALKING", "ONLINE"],
  aggregatorSystems = [
    { name: "WERA", value: "WERA" },
    { name: "FALCON", value: "FALCON" },
    { name: "ZOMATO", value: "ZOMATO" },
  ];

const returnCleanedVariablesSettings = (data) => {
  delete data["__typename"];
  if (data["availableFeilds"]) {
    data["availableFeilds"].map((x) => {
      delete x["__typename"];
    });
  }
  if (data["formSchemas"]) {
    data["formSchemas"].map((x) => {
      delete x["__typename"];
    });
  }
  if (data["customFeilds"]) {
    data["customFeilds"].map((x) => {
      delete x["__typename"];
    });
  }
  if (data["hoursOfOperation"]) {
    data["hoursOfOperation"].map((x) => {
      delete x["__typename"];
    });
  }
  if (data["customCharges"]) {
    delete data["customCharges"]["__typename"];
    data["customCharges"].map((x) => {
      delete x["__typename"];
      x["customChargeList"].map((x) => {
        delete x["__typename"];
      });
    });
  }
  if (data["qrcode"]) {
    delete data["qrcode"]["__typename"];
  }
  return data;
};

const S3_URL = process.env.REACT_APP_S3_URL;

const convertDate = [
  { time: "1:00", hrs: "1:00 AM" },
  { time: "1:30", hrs: "1:30 AM" },
  { time: "2:00", hrs: "2:00 AM" },
  { time: "2:30", hrs: "2:30 AM" },
  { time: "3:00", hrs: "3:00 AM" },
  { time: "3:30", hrs: "3:30 AM" },
  { time: "4:00", hrs: "4:00 AM" },
  { time: "4:30", hrs: "4:30 AM" },
  { time: "5:00", hrs: "5:00 AM" },
  { time: "5:30", hrs: "5:30 AM" },
  { time: "6:00", hrs: "6:00 AM" },
  { time: "6:30", hrs: "6:30 AM" },
  { time: "7:00", hrs: "7:00 AM" },
  { time: "7:30", hrs: "7:30 AM" },
  { time: "8:00", hrs: "8:00 AM" },
  { time: "8:30", hrs: "8:30 AM" },
  { time: "9:00", hrs: "9:00 AM" },
  { time: "9:30", hrs: "9:30 AM" },
  { time: "10:00", hrs: "10:00 AM" },
  { time: "10:30", hrs: "10:30 AM" },
  { time: "11:00", hrs: "11:00 AM" },
  { time: "11:30", hrs: "11:30 AM" },
  { time: "12:00", hrs: "12:00 PM" },
  { time: "12:30", hrs: "12:30 PM" },
  { time: "13:00", hrs: "1:00 PM" },
  { time: "13:30", hrs: "1:30 PM" },
  { time: "14:00", hrs: "2:00 PM" },
  { time: "14:30", hrs: "2:30 PM" },
  { time: "15:00", hrs: "3:00 PM" },
  { time: "15:30", hrs: "3:30 PM" },
  { time: "16:00", hrs: "4:00 PM" },
  { time: "16:30", hrs: "4:30 PM" },
  { time: "17:00", hrs: "5:00 PM" },
  { time: "17:30", hrs: "5:30 PM" },
  { time: "18:00", hrs: "6:00 PM" },
  { time: "18:30", hrs: "6:30 PM" },
  { time: "19:00", hrs: "7:00 PM" },
  { time: "19:30", hrs: "7:30 PM" },
  { time: "20:00", hrs: "8:00 PM" },
  { time: "20:30", hrs: "8:30 PM" },
  { time: "21:00", hrs: "9:00 PM" },
  { time: "21:30", hrs: "9:30 PM" },
  { time: "22:00", hrs: "10:00 PM" },
  { time: "22:30", hrs: "10:30 PM" },
  { time: "23:00", hrs: "11:00 PM" },
  { time: "23:30", hrs: "11:30 PM" },
  { time: "24:00", hrs: "12:00 AM" },
  { time: "00:30", hrs: "12:30 AM" },

  // { time: '00:30', hrs: '1:30 PM' }
];

class AddSubServiceType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: true,
      loading: false,
      isAggregatorEnabled: false,
      imageUpload:
        this.props.editMenuData &&
        Object.keys(this.props.editMenuData).length !== 0 &&
        this.props.editMenuData.image &&
        this.props.editMenuData.image.key
          ? {
              key: this.props.editMenuData.image.key,
              uid: this.props.editMenuData.image.key,
              bucket: this.props.editMenuData.image.bucket,
              region: this.props.editMenuData.image.region,
              url: `${S3_URL}${this.props.editMenuData.image.key}`,
            }
          : {},
      uploadSizeError: false,
      aggregatorSystem: this.props.editMenuData.aggregatorSystem || "WERA",
      siteData: this.props.siteData,
      notes: undefined,
    };
  }

  componentDidMount() {
    const editMenuData = this.props.editMenuData;
    // console.log("editMenuData :::", this.props.siteData)
    if (editMenuData && Object.keys(editMenuData).length !== 0) {
      this.setState({
        isAggregatorEnabled: editMenuData.isAggregatorEnabled,
      });
    }
  }

  enterLoading = () => {
    this.setState({
      loading: true,
    });
  };
  notesChange = (e) => {
    this.setState({
      notes: e.target.value,
    });
  };
  bucket = process.env.REACT_APP_BUCKET;
  s3_Region = process.env.REACT_APP_S3_REGION;
  createLink = (obj) => {
    let link = "";
    if (obj && obj.key) {
      link = `https://${obj.bucket}.s3.amazonaws.com/${obj.key}`;
    }
    return link;
  };
  storeRegister = (variable) => {
    return new Promise((resolve, reject) => {
      this.props.client
        .mutate({
          mutation: storeRegisterONDC,
          variables: {
            payload: variable,
          },
        })
        .then(({ data }) => {
          if (data.storeRegisterONDC) resolve(JSON.parse(data.storeRegisterONDC));
          else reject("Something Went Wrong...");
        })
        .catch((err) => {
          console.log("err :::", err);
          reject("Something Went Wrong...");
        });
    });
  };
  updateFalcon = (variable) => {
    return new Promise((resolve, reject) => {
      this.props.client
        .mutate({
          mutation: updateFalconStore,
          variables: variable,
        })
        .then(({ data }) => {
          resolve(data.updateFalconStore);
        })
        .catch((err) => {
          console.log("err :::", err);
          reject("Something Went Wrong...");
        });
    });
  };
  updateServiceType = (variable) => {
    return new Promise((resolve, reject) => {
      this.props.client
        .mutate({
          mutation: updateServiceTypeSetting,
          variables: variable,
        })
        .then(({ data }) => {
          if (data.updateServiceTypeSetting) resolve(data.updateServiceTypeSetting);
          else reject("Something Went Wrong...");
        })
        .catch((err) => {
          console.log("err :::", err);
          reject("Something Went Wrong...");
        });
    });
  };
  handleSubmit = () => {
    // e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err && this.state.notes) {
        this.enterLoading();
        try {
          console.log("form values", values);
          const slugName = slugify(values.menuName, {
            lower: true,
          });
          if (

             this.props.modalData.slugName === "online-menu" && this.state.isAggregatorEnabled &&
             this.state.aggregatorSystem === "WERA" && 
            (!values.aggregatorMerchantId || values.aggregatorMerchantId === "")
          ) {
            message.error("Aggregator Merchant Id Is a mandatory field.", 2);
            return;
          }

          if (this.state.uploadSizeError && this.state.imageUpload && Object.keys(this.state.imageUpload).length > 0) {
            message.error("Please Upload Image of correct file size.");
            return;
          }

          const editMenuData = this.props.editMenuData;
          const siteId = this.props.siteId;

          if (
            (values.masterServiceType === "appointment-booking" ||
              editMenuData.masterServiceType === "appointment-booking" ||
              editMenuData.slugName === "appointment-booking") &&
            values.reservationType === "WALKING"
          ) {
            const allSerType = this.props.allSerType;
            // console.log("allSerType ::", allSerType, slugName, editMenuData.slugName)
            let iterator;
            for (let i = 0; i < allSerType.length; i++) {
              iterator = allSerType[i];
              // console.log("iterator ::", iterator)
              if (
                (iterator.masterServiceType === "appointment-booking" || iterator.slugName === "appointment-booking") &&
                iterator.reservationType === "WALKING"
              ) {
                // console.log("iterator ::", iterator.reservationType)
                if (editMenuData && Object.keys(editMenuData).length > 0) {
                  if (iterator.slugName !== editMenuData.slugName) {
                    // console.log("if ::", editMenuData.slugName, slugName)
                    message.error(
                      "A Booking Menu with WALKING reservation type already exist, A Site is not allowed to have more than 1 WALKING reservation type for Bookings."
                    );
                    return;
                  }
                } else {
                  // console.log("else ::", editMenuData.slugName, slugName)
                  message.error(
                    "A Booking Menu with WALKING reservation type already exist, A Site is not allowed to have more than 1 WALKING reservation type for Bookings."
                  );
                  return;
                }
              }
            }
          }

          // this.enterLoading();
          let files=[]
          if (this.state.imageUpload) {
            if (Object.keys(this.state.imageUpload).length != 0) {
              if (!this.state.imageUpload.hasOwnProperty("key")) {
                values.imageUpload = {
                  bucket: this.bucket,
                  region: this.s3_Region,
                  key: `${siteId}/menu-images/${uuidv1()}`,
                  mimeType: this.state.imageUpload.type,
                };
                // console.log("if :::");
                  files.push({...values.imageUpload,localUri: this.state.imageUpload.originFileObj,})
                if(files.length>0){
                  files = await convertFileToBase64(files)
                  uploadbase64Files(this.props.client, files)
                 }
              } else {
                const { bucket, key, region } = this.state.imageUpload;
                values.imageUpload = {
                  bucket,
                  key,
                  region,
                };
                console.log("else :::");
              }
            } else {
              values.imageUpload = null;
            }
          }

          let variables;
          if (editMenuData && Object.keys(editMenuData).length !== 0) {
            variables = {
              siteId: editMenuData.siteId,
              slugName: editMenuData.slugName,
              typeName: editMenuData.typeName,
              displayName: values.displayName,
              // masterServiceType: editMenuData.masterServiceType,
              typeDes: editMenuData.typeDes,
              // image: undefined,
              typeStatus: values.menuStatus,
              displayOrder: editMenuData.displayOrder,
              aggregatorMerchantId: values.isAggregatorEnabled && values.aggregatorMerchantId ? values.aggregatorMerchantId : null,
              isAggregatorEnabled: values.isAggregatorEnabled,
              aggregatorSystem: values.isAggregatorEnabled && values.aggregatorMerchantId ? "WERA" : null,
              image: values.imageUpload,
              reservationType: values.reservationType ? values.reservationType : null,
            
            };

            if(variables.aggregatorSystem &&  variables.aggregatorSystem == "WERA" ){
               variables.weraManagedByRecaho  = values.weraManagedByRecaho
            }

            // console.log("variables:::",variables)
            // return
            this.props.client
              .mutate({
                mutation: updateServiceType,
                variables: variables,
              })
              .then(({ data }) => {
                message.success("Updated Successfully...");
                this.setState({
                  loading: false,
                });
              })
              .catch((res) => {
                console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(res, null, 2)}`);
              });
          } else {
            let ondcResponse = {};
            const siteData = this.state.siteData;
            let iterator,
              i,
              storeTimmings = {
                Monday: "",
                Tuesday: "",
                Wednesday: "",
                Thursday: "",
                Friday: "",
                Saturday: "",
                Sunday: "",
              };
            // console.log("storeTimmings :::", storeTimmings);
            // return
            if (this.state.aggregatorSystem === "FALCON") {
              if (!siteData.fssai) {
                throw "Please Update FSSAI into the system";
              }
              if (!siteData.companyProfile || !siteData.siteLogo || !(siteData.siteImages && siteData.siteImages.length)) {
                throw "Please Update Description, logo and Site Images into the system";
              }
              if (!siteData.contactNumber || !siteData.custName || !siteData.contactEmail) {
                throw "Please Update Customer Details into the system";
              }
              if (
                !siteData.footerAddress ||
                !siteData.footerAddress.location ||
                !siteData.footerAddress.location.lat ||
                !siteData.footerAddress.location.long
              ) {
                throw "Please Update Latitude and longitude from the settings";
              }
              if (!siteData.footerAddress.zip || !siteData.footerAddress.city || !siteData.footerAddress.state || !siteData.footerAddress.country) {
                throw "Please Update Address from the settings";
              }

              if (values.storeTimmings && values.storeTimmings.length) {
                for (i = 0; i < values.storeTimmings.length; i++) {
                  iterator = values.storeTimmings[i];
                  if (iterator.enable && iterator.from && iterator.to) {
                    storeTimmings[iterator.day] = `${iterator.from} - ${iterator.to}`;
                  } else {
                    storeTimmings[iterator.day] = "Closed";
                  }
                }
              }
              delete siteData.siteLogo.__typename;
              const ondcObject = {
                name: siteData.companyName,
                description: siteData.companyProfile ? siteData.companyProfile : "",
                logo: this.createLink(siteData.siteLogo),
                // images: ["https://btoc-ecom-prod.s3.amazonaws.com/a78a56fb-5…2ab-4284202c5556/HTMLPage/Recaho-Digital-Logo.png"],
                gstin: siteData.gstNo,
                fssai: siteData.fssai,

                location: { lat: siteData.footerAddress.location.lat, lng: siteData.footerAddress.location.long },
                store_timings: storeTimmings,
                // category: "F&B",
                // subcategory: "F&B",
                delivery_types: ["delivery"],
                // store_status: "1",
                // minimum_order_size: 10,
                website: `https://${siteData.fqdn}/`,
                serving_radius: { value: 5, unit: "km" },
                delivery_support_required: true,
                // gst_liable_items: "vendor",
                // gst_liable_packaging: "vendor",
                owner_contact: siteData.contactNumber,
                owner_name: siteData.custName,
                owner_email: siteData.contactEmail,
                delivery_contact: siteData.contactNumber,
                business_email: siteData.contactEmail,
                brand_name: values.menuName,
                payment: {
                  payment_type: ["CoD", "Prepaid"],
                  beneficiary_name: values.accountHolder,
                  ifsc: values.ifscCode,
                  bank_name: values.bankName,
                  branch_name: values.branchName,
                  bank_account_number: values.accountNum,
                },
              };
              if (siteData.footerAddress) {
                ondcObject["street_address"] = siteData.footerAddress.addressLineOne ? siteData.footerAddress.addressLineOne : "";
                ondcObject.locality = siteData.footerAddress.addressLineOne ? siteData.footerAddress.addressLineOne : "";
                // locality: "Baner";
                // landmark: "sad";
                ondcObject.state = siteData.footerAddress.state ? siteData.footerAddress.state : "";
                ondcObject.city = siteData.footerAddress.city ? siteData.footerAddress.city : "";
                ondcObject.country = siteData.footerAddress.country ? siteData.footerAddress.country : "";
                ondcObject["pin_code"] = siteData.footerAddress.zip ? siteData.footerAddress.zip : "";
              }
              let images = [];
              for (i = 0; i < siteData.siteImages.length; i++) {
                iterator = siteData.siteImages[i];
                delete iterator.__typename;
                images.push(this.createLink(iterator));
              }
              ondcObject.images = images;
              console.log("ondcObject :::", {
                ...ondcObject,
                domain: siteData.fqdn,
                typeValue: "store::" + slugName,
                siteId: siteData.siteId,
              });
              // return;
              // continue

              // return;
              
              ondcResponse = await this.storeRegister(JSON.stringify(ondcObject));
              if (ondcResponse.success != "1") {
                throw `Failed to Register FALCON account, ${JSON.stringify(ondcResponse.details)}`;
              }
            //   console.log("ondcResponse :::", ondcResponse);
              await this.updateFalcon({
                ...ondcObject,
                domain: siteData.fqdn,
                typeValue: "store::" + slugName,
                siteId: siteData.siteId,
                logo: siteData.siteLogo,
                images: siteData.siteImages,
                store_status: "1",
                merchantId: ondcResponse.message,
              });
            }
            variables = {
              siteId: "serviceType::" + siteId,
              slugName: slugName,
              typeName: values.menuName,
              masterServiceType: values.masterServiceType,
              typeDes: values.menuDes ? values.menuDes : undefined,
              typeStatus: values.menuStatus,
              displayOrder: null,
              aggregatorMerchantId: this.state.aggregatorSystem === "FALCON" ? ondcResponse.message : values.aggregatorMerchantId,
              isAggregatorEnabled: values.isAggregatorEnabled,
              aggregatorSystem: values.aggregatorSystem,
              image: values.imageUpload,
              reservationType: values.reservationType ? values.reservationType : undefined,
            };

            if(variables.aggregatorSystem &&  variables.aggregatorSystem == "WERA" ){
              variables.weraManagedByRecaho  = values.weraManagedByRecaho
           }

            // this.createInvoiceFormat(values.masterServiceType, slugName)
            // this.updateAllservice(values.masterServiceType, slugName);
            // return
            this.props.client.mutate({
                mutation: createServiceType,
                variables: variables,
              })
              .then(({ data }) => {
                this.updateAllservice(values.masterServiceType, slugName);
                this.createLogs();
                this.createInvoiceFormat(values.masterServiceType, slugName)
                if (this.state.aggregatorSystem === "FALCON") {
                  let hoursOfOperation = [],
                    bankDetails = [
                      {
                        accountNum: values.accountNum,
                        bankName: values.bankName,
                        accountHolder: values.accountHolder,
                        ifscCode: values.ifscCode,
                        branchName: values.branchName,
                        defaultSelected: true,
                      },
                    ];
                  for (i = 0; i < values.storeTimmings.length; i++) {
                    iterator = values.storeTimmings[i];
                    hoursOfOperation.push({
                      day: iterator.day,
                      status: iterator.enable,
                      start: iterator.from,
                      end: iterator.to,
                    });
                  }
                  this.updateServiceType({
                    domain: siteData.fqdn,
                    typeValue: slugName + "::setting",
                    siteId: siteId,
                    hoursOfOperation,
                    bankDetails,
                    // holidays:this.state.holidayArray
                  });
                }
              })
              .catch((res) => {
                console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(res, null, 2)}`);
                let err1 = JSON.parse(JSON.stringify(res))
                let errorMessage = err1.message.replace('_',' ')
                message.error(errorMessage)
                this.setState({ loading: false });
              });
          }
          // console.log("variables ::", variables);
        } catch (err) {
          message.error(`${err}`);
          this.setState({ loading: false });
        }
        //    return
      } else {
        if (this.state.notes == undefined) {
          message.error("Please Enter Notes");
          this.setState({ loading: false });
        }
      }
    });
  };
  updateAllservice = async (typeValue, slugName) => {
    console.log("In updateAllservice::", slugName);
    let serData = await this.getServiceByServiceType();
    let setting = await this.getServiceTypeSetting(typeValue);
    // console.log("serData ::", serData);
    // return
    let veriSett = returnCleanedVariablesSettings(setting);
    // console.log("Service Data", serData);
    veriSett["typeValue"] = slugName + "::setting";
    console.log("veriSett ::", veriSett);
    let setRes = await this.createServiceTypeSetting(veriSett);
    //  console.log("setRes ::",setRes)
    let finalData = [];
    serData.map((val) => {
      let newSerRatesArr = []
      val.serviceRates.map((val) => {
        let { __typename, ...rest } = val;
        rest['inStock'] = val.inStock == null ? val.status : val.inStock
        newSerRatesArr.push(rest)
      });
      val.addons &&
        val.addons != [] &&
        val.addons.length > 0 &&
        val.addons.map((val) => {
          delete val.__typename;
        });
      //  console.log(val.serviceRates)
      let newMenu = {
        serviceType: slugName,
        rate: val.serviceRates[0] && val.serviceRates[0].rate ? val.serviceRates[0].rate : 0,
        status:  val.serviceRates[0] && val.serviceRates[0].status ?val.serviceRates[0].status : false,
        listRate:val.serviceRates[0] && val.serviceRates[0].listRate ? val.serviceRates[0].listRate : 0,
        // inStock: val.serviceRates[0] && val.serviceRates[0].status ?val.serviceRates[0].status : false
        inStock: val.serviceRates[0] && val.serviceRates[0].inStock == null ? val.serviceRates[0].status : val.serviceRates[0].inStock
      };
      newSerRatesArr.push(newMenu);
      //  console.log("Final Arr",val.serviceRates)
      val.image && delete  val.image.__typename;

      val.additionalImages &&
      val.additionalImages != [] &&
      val.additionalImages.length > 0 &&
      val.additionalImages.map((ele) => {
        delete ele.__typename;
      });
      let obj = {
        siteId: this.props.siteId,
        slugName: val.slugName,
        serviceName: val.serviceName,
        serviceDes: val.serviceDes,
        serviceRates: newSerRatesArr,
        serviceStatus: val.serviceStatus ? val.serviceStatus : false,
        attachedCat: val.attachedCat,
        attachedAddonCat: val.attachedAddonCat,
        station: val.station,
        groupId: val.groupId,
        tags: val.tags,
        tax: val.tax,
        taxType: val.taxType,
        masterProdId: val.masterProdId,
        goodInventory: val.goodInventory,
        badInventory: val.badInventory,
        departmentId: val.departmentId,
        hsnCode: val.hsnCode,
        metaKeyword:val.metaKeyword,
        displayOrder: val.displayOrder,
        pmInventory: val.pmInventory,
        purchaseItemRef: val.purchaseItemRef,
        availableInventory: val.availableInventory,
        tags: val.tags,
        addons: val.addons,
        image: val.image,
        additionalImages: val.additionalImages,
        productType: val.productType,
        costPrice: val.costPrice,
        weightItem: val.weightItem,
        dietryTag: val.dietryTag,
        isVegan: val.isVegan,
        meatType: val.meatType,
        isPCByQty: val.isPCByQty,
        itemLevelName: val.itemLevelName,
        size: val.size,
        isEggless: val.isEggless,
        message: val.message,
        messageLoc: val.messageLoc,
        isCelebrationCake: val.isCelebrationCake,
        containsAlcohol: val.containsAlcohol,
        itemType: val.itemType,
        preparationTime: val.preparationTime,
        packingChargeType: val.packingChargeType,
        packingCharge: val.packingCharge,
        isDiscountApplicable: val.isDiscountApplicable,
      };
      finalData.push(obj);
    });
    // console.log("Final All Data", finalData);

      // return
    if(finalData && finalData.length ){
    let arr = [];
    let res = 15;
    for (let i = 0; i < finalData.length; i++) {
      arr.push(finalData[i]);
      if (i == res) {
        let ress = await this.sendDataEdit(arr);
        if (ress == true) {
          res = res + 15;
          arr = [];
        }else{
          message.error("Product Update Error");
        }
      }
    }
    this.sendDataEdit(arr);
    }
    message.success("Menu Successfully Created");
    this.setState({
      loading: false,
    });
  };
  sendDataEdit = (data) => {
    // console.log("Bulk Data", data);
    return new Promise((resolve, reject) => {
      this.props.client
        .mutate({
          mutation: updateServiceInBulkV2,
          variables: {
            input: data,
          },
        })
        .then(({ data }) => {
          // console.log("Bulk response", data);
          resolve(true);
          // message.success("Successfully Services Created")
        }).catch((res) => {
          resolve(false);
          console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(res, null, 2)}`);
          let err1 = JSON.parse(JSON.stringify(res))
          let errorMessage = err1.message.replace('_',' ')
          message.error(errorMessage)
        });
    });
  };

  
  getServiceByServiceType = () => {
    return new Promise((resolve, reject) => {
      this.props.client
        .query({
          query: getServiceBySiteId,
          variables: {
            siteId: this.props.siteId,
          },
          fetchPolicy: "network-only",
        })
        .then(({ data }) => {
          console.log("getServiceBySiteId ::", data);
          let arr = data.getServiceBySiteId;
          let newArr = [];
          arr.map((ele) => {
            let obj = {};
            if (ele.attachedVariations && ele.attachedVariations.length > 0 && ele.attachedVariations != []) {
              ele.attachedVariations.map((val) => {
                newArr.push(val);
              });
              newArr.push(ele);
            } else {
              newArr.push(ele);
            }
          });
          //   console.log("newArr ::",newArr)
          resolve(newArr);
        })
        .catch((err) => {
          console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(err, null, 2)}`);
          let err1 = JSON.parse(JSON.stringify(err))
          let errorMessage = err1.message.replace('_',' ')
          message.error(errorMessage)
        });
    });
  };
  getServiceTypeSetting = (typeValue) => {
    return new Promise((resolve, reject) => {
      this.props.client
        .query({
          query: getServiceTypeSettingBySite,
          variables: {
            domain: this.props.domain,
            typeValue: typeValue + "::setting",
          },
          fetchPolicy: "network-only",
        })
        .then(({ data }) => {
          console.log("getServiceTypeSettingBySite ::", data);
          resolve(data.getServiceTypeSettingBySite);
        })
        .catch((err) => {
          console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(err, null, 2)}`);
          let err1 = JSON.parse(JSON.stringify(err))
          let errorMessage = err1.message.replace('_',' ')
          message.error(errorMessage)
        });
    });
  };
  createServiceTypeSetting = (record) => {
    return new Promise((resolve, reject) => {
      this.props.client
        .mutate({
          mutation: updateServiceTypeSetting,
          variables: record,
        })
        .then(({ data }) => {
          console.log("response updateServiceTypeSetting ::", data);
          resolve(true);
        }).catch((res) => {
          console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(res, null, 2)}`);
          let err1 = JSON.parse(JSON.stringify(res))
          let errorMessage = err1.message.replace('_',' ')
          message.error(errorMessage)
        });
    });
  };
  getBasicSiteSetting = async (domain) => {
    return new Promise((resolve, reject) => {
      this.props.client
        .query({
          query: getBasicSiteSetting,
          variables: {
            domain: domain,
          },
          fetchPolicy: "no-cache",
        })
        .then(({ data }) => {
          console.log("response updateServiceTypeSetting ::", data.getBasicSiteSettingBySite);
          resolve(data.getBasicSiteSettingBySite || {});
        })
        .catch((err) => {
          console.log("err :::", err);
          reject(err);
        });
    });
    
  };

  createLogs = async () => {
    let obj = {
      siteId: this.props.siteId,
      eventName: "Setup",
      eventType: "MANUAL",
      // reason: this.state.selectedReason,
      note: this.state.notes,
    };
    let resp = await SiteActionEvent(this.props, obj);
    if (resp) {
      this.setState({
        notes: undefined,
      });
    }
  };
  createInvoiceFormat=async(masterServiceType,slugName)=>{
    let invoiceData = await this.getInvoiceFormat(masterServiceType)
    // console.log("invoiceData",invoiceData)
    if(invoiceData.companyLogo && Object.keys(invoiceData.companyLogo).length > 0){
        delete invoiceData.companyLogo.__typename
    }
    if(invoiceData.qrcode && Object.keys(invoiceData.qrcode).length > 0){
        delete invoiceData.qrcode.__typename
    }
    let obj ={
      domain: invoiceData.domain,
      typeValue: `invoiceformat::${slugName}::setting`,
      companyName: invoiceData.companyName,
      companyLogo: invoiceData.companyLogo,
      address: invoiceData.address,
      email: invoiceData.email,
      mobile: invoiceData.mobile,
      gst: invoiceData.gst,
      custName: invoiceData.custName,
      custMobile: invoiceData.custMobile,
      editCustDetails: invoiceData.editCustDetails,
      token: invoiceData.token,
      siteURL: invoiceData.siteURL,
      footerMessage: invoiceData.footerMessage,
      qrcode: invoiceData.qrcode,
      paymentMode: invoiceData.paymentMode,
      custKOTPrint:invoiceData.custKOTPrint,
      custAddress:invoiceData.custAddress,
      invoiceOptionOnBill: invoiceData.invoiceOptionOnBill,
      paytmQrOptions: invoiceData.paytmQrOptions,
      isPayQR: invoiceData.isPayQR,
      upiId: invoiceData.upiId,
      printOperatorInfo: invoiceData.printOperatorInfo,
      itemInstructionOnBill: invoiceData.itemInstructionOnBill
    }
    // console.log("obj",obj)
    this.props.client
    .mutate({
      mutation: updateInvoiceFormatV2,
      variables: obj,
    })
    .then(({ data }) => {
      console.log("response updateInvoiceFormatV2 ::", data);
    }).catch((res) => {
      console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(res, null, 2)}`);
      let err1 = JSON.parse(JSON.stringify(res))
      let errorMessage = err1.message.replace('_',' ')
      message.error(errorMessage)
    });
  }
  getInvoiceFormat=(typeValue)=>{
    return new Promise((resolve,reject)=>{
    this.props.client.query({
      query: getInvoiceFormatV2,
      variables: {
        domain: this.props.domain,
        typeValue:  `invoiceformat::${typeValue}::setting`
      },
      fetchPolicy: 'no-cache'
    })
      .then(({ data }) => {
        // console.log('dataaaaa', data)
        resolve(data.getInvoiceFormatV2)
      })
    })
  }
  onChange = (e) => {
    // console.log('radio checked', e.target.value);
    this.setState({
      value: e.target.value,
    });
  };
  handleclick = () => {
    this.props.form.resetFields(["masterServiceType", "menuName", "menuDes", "menuStatus", "reservationType", "imageUpload"]);
    this.props.handleCancel();
  };

  onChangeAggregatorMenu = (event) => {
    // console.log("event ::;", event)
    this.setState({ isAggregatorEnabled: event, aggregatorSystem: null });
  };

  handleMainImage = ({ file }) => {
    // console.log("data file",file)
    this.setState((prevState, props) => {
      if (Object.keys(prevState.imageUpload).length === 0 && prevState.removedIconImg === false) {
        this.imageResize(file);
      } else {
        return {
          removedIconImg: false,
        };
      }
    });
  };

  handleMainImageRemove = (file) => {
    this.setState({ imageUpload: {}, removedIconImg: true });
    this.props.form.resetFields(["imageUpload"]);
    return true;
  };

  handlePreview = (file) => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };


  imageResize = (img) => {
    if (img) {
      let obj = {};
      let arr = [];
      // console.log("Inresize",img)
      // return
      Resizer.imageFileResizer(
        img.originFileObj,
        267,
        267,
        "JPEG",
        50,
        0,
        (uri) => {
          // console.log("Aft resize",uri)
          obj = {
            ...img,
            name: img.name,
            size: uri.size,
            type: uri.type,
            uid: img.uid,
            originFileObj: uri,
            // localUri: this.state.imageUpload.originFileObj,
            // key: `${siteId}/category/${uuidv1()}`,
            // mimeType: this.state.imageUpload.type
          };
          //  console.log("After Resize", uri,obj);
          // arr.push(obj)
          this.setState({
            // Img: uri,
            //   originFileObj: uri,
            imageUpload: obj,
          });
        },
        "file",
        267,
        267
      );
    }
  };

  onaggregatorSystemChange = async (event) => {
    let combinedData = { ...this.state.siteData };
    if (event === "FALCON") {
      const data = await this.getBasicSiteSetting(this.state.siteData.fqdn);
      combinedData = { ...combinedData, ...data };
    }
    console.log("combinedData :::", combinedData);
    this.setState({ aggregatorSystem: event, siteData: combinedData });
  };

  render() {
    const editMenuData = this.props.editMenuData;
    const shouldDisabled = editMenuData && Object.keys(editMenuData).length !== 0;
    // console.log("Sub Service type::", editMenuData)
    const { getFieldDecorator } = this.props.form;
    const modalData = this.props.modalData;
    const imageUpload = this.state.imageUpload;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const days = [
      { value: "Monday", label: "Monday" },
      { value: "Tuesday", label: "Tuesday" },
      { value: "Wednesday", label: "Wednesday" },
      { value: "Thursday", label: "Thursday" },
      { value: "Friday", label: "Friday" },
      { value: "Saturday", label: "Saturday" },
      { value: "Sunday", label: "Sunday" },
    ];

  // console.log("editMenuData.eventType::::",editMenuData)

    return (
      <>
        <Form style={{ marginTop: "10px" }}>
          <Row gutter={16}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
              <FormItem label={`${shouldDisabled ? modalUiTexts[editMenuData.eventType].titleStart : "Sub Menu"} Name`}>
                {getFieldDecorator(`menuName`, {
                  initialValue: shouldDisabled ? editMenuData.typeName : undefined,
                  rules: [
                    {
                      required: true,
                      message: "Please enter Menu Name",
                    },
                  ],
                })(<Input disabled={shouldDisabled} placeholder="Menu Name" style={{ width: "100%" }} />)}
              </FormItem>
            </Col>
            { !editMenuData.masterServiceType  && <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
              <FormItem label={`display Name`}>
                {getFieldDecorator(`displayName`, {
                  initialValue: editMenuData.displayName? editMenuData.displayName : undefined,
                  rules: [
                    {
                      required: false,
                      message: "Please enter display Name",
                    },
                  ],
                })(<Input placeholder="display Name" style={{ width: "100%" }} />)}
              </FormItem>
            </Col>}
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
              <FormItem label="Description">
                {getFieldDecorator(`menuDes`, {
                  trigger: "onBlur",
                  valuePropName: "defaultValue",
                  //    initialValue: this.state.settingData.companyName,
                  initialValue: shouldDisabled ? editMenuData.typeDes : undefined,
                  rules: [
                    {
                      required: false,
                      message: "Please enter Menu Description",
                    },
                  ],
                })(<Input disabled={shouldDisabled} placeholder="Menu Description" style={{ width: "100%" }} />)}
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
              <FormItem
                label={`${shouldDisabled ? modalUiTexts[editMenuData.eventType].titleStart : "Sub Menu"} Image`}
                // label="Sub Menu Image"
              >
                {getFieldDecorator(`imageUpload`, {
                  initialValue: this.state.imageUpload,
                  rules: [
                    {
                      required: false,
                      message: "Please enter Menu Description",
                    },
                  ],
                })(
                  <Upload
                    name="file"
                    listType="picture-card"
                    defaultFileList={Object.keys(imageUpload).length != 0 ? [imageUpload] : null}
                    data={imageUpload ? imageUpload : ""}
                    // onPreview={this.handlePreview}
                    onChange={this.handleMainImage}
                    onRemove={this.handleMainImageRemove}
                    // beforeUpload={this.beforeUpload}
                  >
                    {Object.keys(imageUpload).length != 0 ? null : uploadButton}
                  </Upload>
                )}
              </FormItem>
            </Col>
           
          </Row>
          <Row gutter={16}>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
              <FormItem label="Status">
                {getFieldDecorator(`menuStatus`, {
                  //    trigger: 'onBlur',
                  //    valuePropName: 'defaultValue',
                  initialValue: shouldDisabled ? editMenuData.typeStatus : this.state.value,
                  rules: [
                    {
                      required: true,
                      message: "Please Select Menu Status",
                    },
                  ],
                })(
                  <Radio.Group onChange={this.onChange} value={this.state.value}>
                    <Radio value={true}>Active</Radio>
                    <Radio value={false}>Inactive</Radio>
                  </Radio.Group>
                )}
              </FormItem>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
              <FormItem label="Master Service">
                {getFieldDecorator(`masterServiceType`, {
                  initialValue: shouldDisabled ? editMenuData.masterServiceType : this.props.modalData.slugName,
                  rules: [
                    {
                      required: false,
                      message: "Please enter Menu Service",
                    },
                  ],
                })(<Input disabled placeholder="Menu Service" style={{ width: "100%" }} />)}
              </FormItem>
            </Col>

            {modalData.slugName === "appointment-booking" && (
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <FormItem label="Reservation Type">
                  {getFieldDecorator(`reservationType`, {
                    //    trigger: 'onBlur',
                    //    valuePropName: 'defaultValue',
                    initialValue: shouldDisabled ? editMenuData.reservationType : undefined,
                    rules: [
                      {
                        required: false,
                        message: "Please Select Reservation Type",
                      },
                    ],
                  })(
                    <Select allowClear placeholder="Please Select Reservation Type" style={{ width: "100%" }}>
                      {reservationType.map((item) => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  )}
                </FormItem>
              </Col>
            )}

            {modalData.slugName === "online-menu" && (
              <>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                  <FormItem label="Aggregator Menu">
                    {getFieldDecorator(`isAggregatorEnabled`, {
                      initialValue: this.state.isAggregatorEnabled,
                      rules: [
                        {
                          required: false,
                          message: "Please enter Menu Description",
                        },
                      ],
                    })(<Switch onChange={this.onChangeAggregatorMenu} checked={this.state.isAggregatorEnabled} />)}
                  </FormItem>
                </Col>
                {this.state.isAggregatorEnabled && (
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                    <FormItem label="Aggregator System">
                      {getFieldDecorator(`aggregatorSystem`, {
                        initialValue: shouldDisabled ? editMenuData.aggregatorSystem : undefined,
                        //    initialValue: this.state.isAggregatorEnabled,
                        rules: [
                          {
                            required: true,
                            message: "Please enter Aggregator Merchant Id",
                          },
                        ],
                      })(
                        <Select
                          disabled={shouldDisabled}
                          showSearch
                          placeholder="Search to Meat Type"
                          style={{ width: "100%" }}
                          onChange={this.onaggregatorSystemChange}
                        >
                          {aggregatorSystems.map((type) => (
                            <Option value={type.value} key={type.value}>
                              {type.name}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                )}
                {this.state.isAggregatorEnabled && (this.state.aggregatorSystem !== "FALCON" || shouldDisabled) && (
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                    <FormItem label="Aggregator Merchant Id">
                      {getFieldDecorator(`aggregatorMerchantId`, {
                        initialValue: shouldDisabled ? editMenuData.aggregatorMerchantId : undefined,
                        //    initialValue: this.state.isAggregatorEnabled,
                        rules: [
                          {
                            required: true,
                            message: "Please enter Aggregator Merchant Id",
                          },
                          {
                            pattern: this.state.aggregatorSystem == 'WERA' ? /^\d{4}$/ : /^[a-zA-Z0-9]+$/,
                            message: "Aggregator Merchant Id must be a 4-digit number",
                          },
                        ],
                      })(<Input disabled={shouldDisabled}  placeholder="Aggregator Merchant Id" style={{ width: "100%" }} />)}
                    </FormItem>
                  </Col>
                )}

               {this.state.isAggregatorEnabled && this.state.aggregatorSystem && this.state.aggregatorSystem == "WERA" && (
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                    <FormItem label="Is Managed By Recaho">
                      {getFieldDecorator(`weraManagedByRecaho`, {
                        initialValue: editMenuData && Object.keys(editMenuData).length !== 0 ? editMenuData.weraManagedByRecaho : false,
                        rules: [
                          {
                            required: true,
                            message: "Please Select Menu Status",
                          },
                        ],
                      })(
                        <Radio.Group >
                          <Radio value={true}>Active</Radio>
                          <Radio value={false}>Inactive</Radio>
                        </Radio.Group>
                      )}
                    </FormItem>
                  </Col>
                )}

                {/* {
                            this.state.aggregatorSystem === "FALCON" && <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                             <FormItem
                                 label="Minumum Order Size"
                             >
                                 {getFieldDecorator(`minimumOrderSize`, {
                                    //  initialValue: shouldDisabled ? editMenuData.aggregatorMerchantId : undefined,
                                 //    initialValue: this.state.isAggregatorEnabled,
                                     rules: [{
                                         required: true,
                                         message: "Please enter Minumum Order Size",
                                     }]
                                 })(
                                     <Input placeholder="Minumum Order Size" style={{ width: '100%' }} />
                                 )}
                             </FormItem>
                         </Col>
                        } */}
                {this.state.aggregatorSystem === "FALCON" && !shouldDisabled && (
                  <>
                    <Col span={24}>
                      {days.map((val, i) => {
                        return (
                          <Row gutter={16}>
                            <Col xs={{ span: 4 }} sm={{ span: 4 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                              <FormItem>
                                {getFieldDecorator(`storeTimmings[${i}]day`, {
                                  initialValue: val.label,
                                  rules: [
                                    {
                                      required: false,
                                    },
                                  ],
                                })(<span style={{ fontSize: "16px" }}>{val.label}</span>)}
                              </FormItem>
                            </Col>

                            <Col xs={{ span: 4 }} sm={{ span: 4 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                              <FormItem>
                                {getFieldDecorator(`storeTimmings[${i}]enable`, {
                                  initialValue: false,
                                  // initialValue: setting.hoursOfOperation && setting.hoursOfOperation[i] && setting.hoursOfOperation[i].status != undefined && setting.hoursOfOperation[i].status != null ? setting.hoursOfOperation[i].status : true,
                                })(
                                  <Switch
                                    style={{ marginRight: "10px" }}
                                    // onChange={(e) => this.onChange(e)}
                                    //   checked={this.checked}
                                  />
                                )}
                              </FormItem>
                            </Col>
                            <Col xs={{ span: 10 }} sm={{ span: 10 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                              <FormItem>
                                {getFieldDecorator(`storeTimmings[${i}]from`, {
                                  // initialValue: setting.hoursOfOperation && setting.hoursOfOperation[i] && setting.hoursOfOperation[i].start ? setting.hoursOfOperation[i].start : 'Select From',
                                  rules: [
                                    {
                                      required: false,
                                    },
                                  ],
                                })(
                                  <Select placeholder="Select slot">
                                    {convertDate.map((t) => (
                                      <Option value={t.time}>
                                        <span>{t.hrs}</span>
                                      </Option>
                                    ))}
                                  </Select>
                                )}
                              </FormItem>
                            </Col>
                            <Col xs={{ span: 10 }} sm={{ span: 10 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                              <FormItem>
                                {getFieldDecorator(`storeTimmings[${i}]to`, {
                                  // initialValue:  setting.hoursOfOperation && setting.hoursOfOperation[i] && setting.hoursOfOperation[i].end ? setting.hoursOfOperation[i].end : 'Select To',
                                  rules: [
                                    {
                                      required: false,
                                    },
                                  ],
                                })(
                                  <Select placeholder="Select slot">
                                    {convertDate.map((t) => (
                                      <Option value={t.time}>
                                        <span>{t.hrs}</span>
                                      </Option>
                                    ))}
                                  </Select>
                                )}
                              </FormItem>
                            </Col>
                          </Row>
                        );
                      })}
                    </Col>
                    <Divider>Bank Details</Divider>
                    <Row gutter={[16, 8]} className="reset-form-styles">
                      <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                        <FormItem label="Bank Name">
                          {getFieldDecorator("bankName", {
                            // initialValue: bankDetails.bankName,
                            rules: [
                              {
                                required: true,
                                message: "Please enter Bank Name.",
                              },
                            ],
                          })(<Input placeholder="Bank Name" />)}
                        </FormItem>
                      </Col>
                      <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                        <FormItem label="Branch Name">
                          {getFieldDecorator("branchName", {
                            // initialValue: bankDetails.bankName,
                            rules: [
                              {
                                required: true,
                                message: "Please enter Branch Name.",
                              },
                            ],
                          })(<Input placeholder="Branch Name" />)}
                        </FormItem>
                      </Col>
                      <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                        <FormItem label="Account Holder">
                          {getFieldDecorator("accountHolder", {
                            // initialValue: bankDetails.accountHolder,
                            rules: [
                              {
                                required: true,
                                message: "Please enter Account Holder.",
                              },
                            ],
                          })(<Input placeholder="Account Holder" />)}
                        </FormItem>
                      </Col>
                      <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                        <FormItem label="Account Number">
                          {getFieldDecorator("accountNum", {
                            // initialValue: bankDetails.accountNum,
                            rules: [
                              {
                                required: true,
                                message: "Please enter Account Number.",
                              },
                            ],
                          })(<Input type="number" placeholder="Account Number" />)}
                        </FormItem>
                      </Col>
                      <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                        <FormItem label="IFSC Code">
                          {getFieldDecorator("ifscCode", {
                            // initialValue: bankDetails.ifscCode,
                            rules: [
                              {
                                required: true,
                                message: "Please enter IFSC Code.",
                              },
                            ],
                          })(<Input placeholder="IFSC Code" />)}
                        </FormItem>
                      </Col>
                    </Row>
                  </>
                )}
                {/* <Col  xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}} >
                            <FormItem
                                label="Aggregator Menu"
                            >
                                {getFieldDecorator(`isAggregatorEnabled`, {
                                   initialValue: this.state.isAggregatorEnabled,
                                    rules: [{
                                        required: true,
                                        message: "Please enter Menu Description",
                                    }]
                                })(
                                    <Switch onChange={this.onChangeAggregatorMenu} />
                                )}
                            </FormItem>
                        </Col> */}
              </>
            )}
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Popconfirm
                onConfirm={this.handleSubmit}
                title={<TextArea rows={2} value={this.state.notes} placeholder="Notes" onChange={this.notesChange} />}
                okText="Save"
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.state.loading}
                  style={{ background: "#389e0d", color: "#fff", marginBottom: "5%", marginRight: "20px" }}
                >
                  Submit
                </Button>
              </Popconfirm>
              <Button type="default" onClick={this.handleclick} style={{ background: "#f44336", color: "#fff", marginBottom: "5%" }}>
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

const WrappedAddSubServiceType = Form.create({ name: "JMenu_form" })(AddSubServiceType);
export default withApollo(WrappedAddSubServiceType);
